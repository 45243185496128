import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Component from "@reach/component-component";
import styled, { css } from "styled-components";
import sortBy from "lodash/sortBy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { joinEvent, leadEvent, leaveEvent } from "../actions";

import { normalizeCharsForSort } from "../helpers";

import * as strings from "../constants/strings";

import {
  LightboxOverlay,
  LightboxContent,
  LightboxButtonRow,
  LightboxCloseContainer,
  LightboxClose,
} from "../components/Lightbox";
import Avatar from "../components/Avatar";
import Button, { TextButton } from "../components/Button";
import { DialogHeading } from "../components/Headings";

const EventUserSelectButton = styled(Button)`
  border: 0;
  background: var(--color-secondary);
  color: var(--color-cta--secondary);
  margin-left: 5px;

  ${(props) =>
    !props.visibleButton &&
    css`
      display: none;
    `};
`;

const EventUserSelectContainer = styled.ul`
  display: flex;
  flex-flow: column;
  list-style: none;
  margin: 0 0 10px;
  padding: 0;
`;

const EventUserSelectItem = styled.li`
  display: flex;
  margin: 0 -5px;
  padding: 0;
`;

const EventUserSelectItemFocus = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 5px;
  width: 100%;

  ${(props) =>
    props.isLead &&
    css`
      ${EventUserSelectStatus} {
        color: var(--brand-primary);
      }
      ${EventUserSelectName} {
      }
    `};

  /* ${(props) =>
    props.isChecked &&
    css`
      ${EventUserSelectName} {
        color: var(--color-cta);
      }
    `}; */

  /* ${(props) =>
    props.isChecked &&
    props.isLead &&
    css`
      ${EventUserSelectName} {
        color: var(--brand-primary);
      }
    `}; */

  &:hover {
    background: hsla(var(--color-cta-hsl), 0.1);
  }
`;

const EventUserSelectName = styled.div`
  font-size: 15px;
  margin: 0 0 0 7px;
`;

const EventUserSelectStatus = styled.div`
  color: var(--color-cta);
  display: flex;
  font-size: 18px;
  margin: 0 0 0 auto;
  overflow: hidden;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
    right: -9999px;
    top: -9999px;
  }
`;

const Badge = styled.div`
  background-color: var(--brand-primary);
  border-radius: var(--border-radius-sm);
  color: var(--box-bg);
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  margin: -1px 0 0 5px;
  padding: 4px 4px 3px;
  text-transform: uppercase;
`;

export class EventUserSelect extends React.Component {
  handleMembership = (e, user) => {
    const { event, mode, joinEvent, leadEvent, leaveEvent } = this.props;
    const isParticipant = event.participants.includes(user.id);
    const isLead = event.leads.includes(user.id);

    e.preventDefault();

    const confirmMoveToParticipants = () => {
      if (isLead) {
        if (
          window.confirm(
            `${user.first_name} ${strings.EVENT.confirm_move_lead}`
          )
        ) {
          joinEvent(event.id, user.id);
        }
      }
    };

    if (mode === "leads") {
      isLead ? leaveEvent(event.id, user.id) : leadEvent(event.id, user.id);
    } else {
      if (isLead) {
        confirmMoveToParticipants();
      } else {
        isParticipant
          ? leaveEvent(event.id, user.id)
          : joinEvent(event.id, user.id);
      }
    }
  };

  renderItem = (event, user) => {
    const { mode } = this.props;

    const isParticipant = event.participants.includes(user.id);
    const isLead = event.leads.includes(user.id);
    const includeUser = user.is_in_house || isParticipant || isLead;

    const checked = () => {
      if (mode === "participants") {
        return isParticipant;
      } else {
        return isLead;
      }
    };

    if (!includeUser) {
      return false;
    }

    return (
      <>
        <EventUserSelectItemFocus
          isChecked={checked()}
          isLead={isLead}
          mode={mode}
        >
          <Avatar userId={user.id} size="s" style={{ border: 0 }} />
          <EventUserSelectName>
            {user.first_name} {user.last_name}
          </EventUserSelectName>

          {isLead && <Badge>Lead</Badge>}

          <EventUserSelectStatus>
            <input
              defaultChecked={checked()}
              onClick={(e) => this.handleMembership(e, user)}
              type="checkbox"
            />
            {mode === "participants" && isLead ? (
              <FontAwesomeIcon icon={["fas", "check-circle"]} />
            ) : (
              <FontAwesomeIcon
                icon={checked() ? ["fas", "check-circle"] : ["far", "circle"]}
              />
            )}
          </EventUserSelectStatus>
        </EventUserSelectItemFocus>
      </>
    );
  };

  render() {
    const { mode, event, disabled, visibleButton, children } = this.props;

    return (
      <Component initialState={{ showDialog: false }}>
        {({ state, setState }) => (
          <>
            {children && visibleButton ? (
              <TextButton
                onClick={() => {
                  if (!disabled) {
                    setState({ showDialog: true });
                  }
                }}
                title={strings.EVENT.choose[mode]}
              >
                {children}
              </TextButton>
            ) : (
              <EventUserSelectButton
                disabled={disabled}
                inverted
                onClick={() => {
                  if (!disabled) {
                    setState({ showDialog: true });
                  }
                }}
                round
                small
                title={strings.EVENT.choose[mode]}
                visibleButton={visibleButton}
              >
                <>
                  <FontAwesomeIcon icon={["fas", "pencil"]} />
                </>
              </EventUserSelectButton>
            )}

            <LightboxOverlay
              isOpen={state.showDialog}
              onDismiss={() => setState({ showDialog: false })}
            >
              <LightboxContent
                aria-label={strings.EVENT.choose[mode]}
                size="sm"
              >
                <LightboxCloseContainer>
                  <LightboxClose
                    inverted
                    onClick={() => setState({ showDialog: false })}
                    round
                    small
                    title={strings.LIGHTBOX.close}
                  >
                    <FontAwesomeIcon icon={["fas", "times"]} />
                  </LightboxClose>
                </LightboxCloseContainer>
                <DialogHeading>{strings.EVENT.choose[mode]}</DialogHeading>
                <EventUserSelectContainer>
                  {this.props.users.map((user, i) => (
                    <EventUserSelectItem key={i}>
                      {this.renderItem(event, user)}
                    </EventUserSelectItem>
                  ))}
                </EventUserSelectContainer>
                <LightboxButtonRow>
                  <Button
                    onClick={() => setState({ showDialog: false })}
                    type="button"
                  >
                    <span>{strings.LOGIN.explainer_ok}</span>
                  </Button>
                </LightboxButtonRow>
              </LightboxContent>
            </LightboxOverlay>
          </>
        )}
      </Component>
    );
  }
}

EventUserSelect.propTypes = {
  disabled: PropTypes.bool,
  event: PropTypes.object.isRequired,
  joinEvent: PropTypes.func.isRequired,
  leadEvent: PropTypes.func.isRequired,
  leaveEvent: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  visibleButton: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  events: state.events,
  users: sortBy(
    Object.values(state.users),
    [
      (user) =>
        user && user.first_name && normalizeCharsForSort(user.first_name),
      (user) => user && user.last_name && normalizeCharsForSort(user.last_name),
    ],
    [("first_name", "last_name")]
  ),
});

const mapDispatchToProps = {
  joinEvent,
  leadEvent,
  leaveEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventUserSelect);
