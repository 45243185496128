import * as ACTION from "../actions/types";
import mapKeys from "lodash/mapKeys";

const initialState = {
  items: [],
  isFetching: false,
  error: false,
};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.PAGES_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case ACTION.PAGES_FETCH_SUCCESS:
      return {
        ...state,
        items: { ...state.items, ...mapKeys(action.payload, "slug") },
        isFetching: false,
        error: false,
      };
    case ACTION.PAGES_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case ACTION.PAGE_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
        error: false,
      };
    default:
      return state;
  }
};

export default pageReducer;
