import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "@reach/router";

import backIcon from "../icons/back.svg";

const BackLinkElement = styled(Link)`
  align-items: center;
  color: var(--color-cta);
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 15px;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;

  &:hover > span {
    transform: translateX(-3px);
  }

  @media (min-width: 1001px) {
    display: flex;
  }
`;

const BackLinkIcon = styled.span`
  display: block;
  height: 20px;
  margin-right: 6px;
  transition: transform var(--transition-time);
  width: 20px;

  img {
    max-width: 100%;
  }
`;

export const BackLink = (props) => {
  return (
    <BackLinkElement to={props.to}>
      <BackLinkIcon>
        <img src={backIcon} alt="" />
      </BackLinkIcon>
      {props.children}
    </BackLinkElement>
  );
};

BackLink.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string,
};
