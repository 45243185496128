import { toast } from "react-toastify";
import pick from "lodash/pick";

import * as ACTION from "./types";
import django from "../apis/django";
import { captureException } from "../helpers";
import { TOAST_AUTOHIDE } from "../constants/index";

export const fetchMe = () => async (dispatch) => {
  dispatch({ type: ACTION.ME_FETCH_REQUEST });

  try {
    const response = await django.get("/users/me/");
    dispatch({
      type: ACTION.ME_FETCH_SUCCESS,
      payload: response.data.user,
    });
  } catch (error) {
    dispatch({
      type: ACTION.ME_FETCH_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

export const editMe = (formValues) => async (dispatch, getState) => {
  dispatch({ type: ACTION.ME_UPDATE_REQUEST });

  const answered = formValues.questions
    // only pass ID and answer to form values
    .map((item) => pick(item, ["id", "answer"]));
  // only pass answers that have been filled out
  // .filter(item => item.answer !== "");

  try {
    const response = await django.patch(`/users/me/`, {
      ...formValues,
      questions: answered,
    });
    dispatch({
      type: ACTION.ME_UPDATE_SUCCESS,
      payload: response.data,
    });
    toast.success("Your profile was updated", {
      autoClose: TOAST_AUTOHIDE,
    });
    // Update current user also in global user list
    dispatch(fetchUser(getState().user.id, true));
  } catch (error) {
    dispatch({
      type: ACTION.ME_UPDATE_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

export const fetchAvatar = () => async (dispatch) => {
  dispatch({ type: ACTION.AVATAR_FETCH_REQUEST });

  try {
    const response = await django.get("/users/me/");
    dispatch({
      type: ACTION.AVATAR_FETCH_SUCCESS,
      payload: {
        profile_picture: response.data.profile_picture,
        profile_picture_small: response.data.profile_picture_small,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION.AVATAR_FETCH_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

export const editAvatar = (file) => async (dispatch, getState) => {
  dispatch({ type: ACTION.AVATAR_UPLOAD_START });

  const data = new FormData();
  data.append("profile_picture", file);

  try {
    const response = await django.patch(`/users/me/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: ACTION.AVATAR_UPLOAD_PROGRESS,
          payload: percentCompleted,
        });
      },
    });
    dispatch({
      type: ACTION.AVATAR_UPLOAD_SUCCESS,
      payload: {
        profile_picture: response.data.profile_picture,
        profile_picture_small: response.data.profile_picture_small,
      },
    });
    // Update current user also in global user list:
    dispatch(fetchUser(getState().user.id, true));
  } catch (error) {
    dispatch({
      type: ACTION.AVATAR_UPLOAD_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

export const resetAvatarUpload = () => async (dispatch) => {
  dispatch({ type: ACTION.AVATAR_UPLOAD_RESET });
};

export const fetchUsers = () => async (dispatch) => {
  dispatch({ type: ACTION.USERS_FETCH_REQUEST });

  try {
    const response = await django.get("/users/");
    dispatch({
      type: ACTION.USERS_FETCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ACTION.USERS_FETCH_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

export const fetchUser = (userId, reload = false) => async (
  dispatch,
  getState
) => {
  // Check whether user has been loaded to the store previously.
  // We don't want to hit the API otherwise.
  const wasLoaded = getState().users[userId];

  // We can force the reload of a user, e.g. when the user changes their
  // avatar or name and needs to be updated in the global user list
  if (!wasLoaded || reload) {
    dispatch({
      type: ACTION.USER_FETCH_REQUEST,
      payload: userId,
    });

    try {
      const response = await django.get(`/users/${userId}/`);
      dispatch({
        type: ACTION.USER_FETCH_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ACTION.USER_FETCH_ERROR,
        payload: error.message,
      });
      toast.error(error.message);
      captureException(error);
    }
  }
};
