import jwt_decode from "jwt-decode";
import * as AUTH from "../actions/types";

const initialState = {
  access: undefined,
  // refresh: undefined,
  loading: true,
  token: undefined,
  isAuthenticated: false,
  errors: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // case AUTH.TOKEN_CHECK:
    //   return {
    //     ...state,
    //     loading: true,
    //   }
    case AUTH.TOKEN_MISSING:
      return {
        ...state,
        loading: false,
      };
    case AUTH.LOGIN_SUCCESS:
      return {
        access: {
          token: action.payload.token,
          ...jwt_decode(action.payload.token),
        },
        // refresh: {
        //   token: action.payload.refresh,
        //   ...jwt_decode(action.payload.refresh),
        // },
        isAuthenticated: true,
        loading: true,
        errors: {},
      };
    case AUTH.INITIALIZING:
      return {
        ...state,
        loading: true,
      };
    case AUTH.READY:
      return {
        ...state,
        loading: false,
      };
    case AUTH.TOKEN_RECEIVED:
      return {
        ...state,
        access: {
          token: action.payload.token,
          ...jwt_decode(action.payload.token),
        },
      };
    case AUTH.LOGIN_ERROR:
    case AUTH.TOKEN_ERROR:
      return {
        access: undefined,
        isAuthenticated: false,
        // refresh: undefined,
        errors: action.payload.response || {
          non_field_errors: action.payload.statusText,
        },
      };
    case AUTH.LOGOUT:
      return {
        ...initialState,
        loading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
