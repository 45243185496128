export const DASHBOARD = "/";
export const LOGIN = "/login";
export const PENDING = "/pending";
export const PROFILE = "/profile";
export const EVENT_LIST = "/events";
export const EVENTS_FILTERED = "/events/:filter";
export const EVENT_DIR = "/event";
export const EVENT_VIEW = "/event/:itemId";
export const EVENT_EDIT = "/event/:itemId/edit/";
export const EVENT_CREATE = "/event/new/";
export const USERS = "/people";
export const USER_ID = "/people/:userId";
export const PAGE_DIR = "/info";
export const PAGE_VIEW = "/info/:slug";
export const PAGE_PRIVACY = "/info/privacy-policy";
