import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Form, Formik } from "formik";
import Component from "@reach/component-component";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LightboxOverlay, LightboxContent } from "../components/Lightbox";
import Button, { ButtonRow } from "../components/Button";
import { DialogHeading } from "../components/Headings";
import UploadBar from "../components/UploadBar";

import { editAvatar, resetAvatarUpload } from "../actions";

import * as strings from "../constants/strings";

const AvatarButton = styled(Button)`
  background-color: var(--box-bg);
`;

const AvatarFrame = styled.div`
  border-radius: 100%;
  display: flex;
  height: 240px;
  overflow: hidden;
  width: 240px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto 15px;

  .img-thumbnail {
    object-fit: cover;
    object-position: 50% 50%;
  }
`;

const ThumbFrame = styled.div`
  border-radius: 100%;
  height: 240px;
  overflow: hidden;
  width: 240px;
`;

const ThumbImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const AvatarDrop = styled.div`
  align-items: center;
  border: 2px dashed var(--color-border--neutral);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  text-align: center;
  overflow: hidden;
  height: 100%;
  width: 100%;

  &:hover {
    color: var(--color-cta);
    border-color: var(--color-cta);
  }

  ${(props) =>
    props.isDragReject &&
    css`
      color: var(--color-alert) !important;
      border-color: var(--color-alert) !important;
    `};

  ${(props) =>
    props.isDragActive &&
    css`
      color: var(--color-cta);
      border-color: var(--color-cta);
    `};

  .svg-inline--fa {
    font-size: 32px;
    margin-bottom: 15px;
    opacity: 0.7;
  }
`;

const AvatarDropText = styled.div`
  width: 90%;
`;

const AvatarUploadBar = styled(UploadBar)`
  margin: 0 auto 30px;
  width: 180px;
`;

export class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file } = this.props;
    // const { loading, thumb } = this.state;
    const { thumb } = this.state;

    if (!file) {
      return null;
    }

    // if (loading) {
    //   return <p>loading...</p>;
    // }

    return (
      <ThumbFrame>
        <ThumbImage src={thumb} alt="" />
      </ThumbFrame>
    );
  }
}

export class ProfileAvatar extends React.Component {
  state = {
    submitting: false,
    complete: false,
  };

  handleSubmit = (formValues) => {
    this.props.editAvatar(formValues.file);
  };

  render() {
    return (
      <Component initialState={{ showDialog: false }}>
        {({ state, setState }) => (
          <div>
            <AvatarButton
              small
              round
              inverted
              onClick={(e) => {
                e.preventDefault();
                setState({ showDialog: true });
              }}
              title={strings.PROFILE.avatar_change}
            >
              <>
                <FontAwesomeIcon icon={["fas", "pencil"]} />
              </>
            </AvatarButton>

            <LightboxOverlay
              isOpen={state.showDialog}
              onDismiss={() => setState({ showDialog: false })}
            >
              <LightboxContent
                aria-label={strings.PROFILE.avatar_overlay_arialabel}
                size="sm"
              >
                <Formik
                  initialValues={{
                    file: null,
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    this.handleSubmit(values);
                    setSubmitting(false);
                  }}
                >
                  {({ values, isSubmitting, setFieldValue }) => (
                    <>
                      <DialogHeading>
                        {strings.PROFILE.avatar_upload_header}
                      </DialogHeading>
                      <Form>
                        <AvatarFrame>
                          <Dropzone
                            accept="image/png,image/gif,image/jpeg"
                            multiple={false}
                            onDrop={(acceptedFiles) => {
                              if (acceptedFiles.length === 0) {
                                return;
                              }

                              setFieldValue("file", acceptedFiles[0]);
                            }}
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                              isDragAccept,
                              isDragReject,
                            }) => {
                              const text = () => {
                                if (isDragReject) {
                                  return strings.PROFILE.avatar_rejected;
                                }

                                if (!values.file) {
                                  return strings.PROFILE.avatar_instructions;
                                }
                              };

                              if (values.file) {
                                return;
                              }

                              return (
                                <AvatarDrop
                                  {...getRootProps({
                                    isDragActive,
                                    isDragAccept,
                                    isDragReject,
                                  })}
                                >
                                  <input {...getInputProps()} />
                                  <FontAwesomeIcon icon={["fas", "images"]} />
                                  <AvatarDropText>{text()}</AvatarDropText>
                                </AvatarDrop>
                              );
                            }}
                          </Dropzone>

                          <Thumb file={values.file} />
                        </AvatarFrame>

                        <AvatarUploadBar
                          active={this.props.user.avatar_upload ? 1 : 0}
                          percent={this.props.user.avatar_upload}
                          complete={this.props.user.avatar_upload_complete}
                        />

                        <ButtonRow>
                          {this.props.user.avatar_upload_complete ? (
                            <Button
                              type="button"
                              onClick={() => {
                                setState({ showDialog: false });
                                this.props.resetAvatarUpload();
                              }}
                            >
                              {strings.PROFILE.avatar_ok}
                            </Button>
                          ) : (
                            <>
                              <Button
                                secondary
                                onClick={() => setState({ showDialog: false })}
                                type="button"
                              >
                                {strings.PROFILE.avatar_cancel}
                              </Button>
                              <Button
                                type="submit"
                                disabled={isSubmitting || !values.file}
                              >
                                {strings.PROFILE.avatar_upload_button}
                              </Button>
                            </>
                          )}
                        </ButtonRow>
                      </Form>
                    </>
                  )}
                </Formik>
              </LightboxContent>
            </LightboxOverlay>
          </div>
        )}
      </Component>
    );
  }
}

ProfileAvatar.propTypes = {
  editAvatar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  editAvatar,
  resetAvatarUpload,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAvatar);
