import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Landing, { LandingText } from "../components/Landing";
import { socialLinks } from "../branding/brandData";
import SocialIcons from "../components/SocialIcons";
import { H1 } from "../components/Headings";

import * as brandData from "../branding/brandData";

const Social = styled.div`
  svg {
    color: #fff;
  }

  small {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    color: var(--text-grey);
  }
`;

const ConfirmRegistration = (props) => {
  return (
    <Landing>
      <LandingText>
        <FontAwesomeIcon icon={["fas", "umbrella-beach"]} />
        <H1>
          {brandData.CONFIRMATION.header}
          {props.userFirstName && `, ${props.userFirstName}`}!
        </H1>
        <p
          dangerouslySetInnerHTML={{
            __html: brandData.CONFIRMATION.text.replace(
              "%1",
              process.env.REACT_APP_NOTIFICATIONS_EMAIL
            ),
          }}
        />
      </LandingText>

      <Social>
        <small>{brandData.CONFIRMATION.social}</small>
        <SocialIcons socialLinks={socialLinks} />
      </Social>
    </Landing>
  );
};

export default ConfirmRegistration;
