import styled from "styled-components";

// We need a wrapper to prevent Router from scrolling to the top
// of the app, effectively hiding it behind the fixed header
export const AppWrapper = styled.div`
  margin: 0 auto;
  max-width: var(--app-width-xs);
  min-height: 100vh;
  padding: var(--app-top-padding) 10px var(--app-bottom-padding);

  @media (min-width: 1001px) {
    max-width: var(--app-width-lg);
    padding: var(--app-top-padding) 20px var(--app-bottom-padding);
  }
`;

export const CarouselWrapper = styled.div`
  @media (max-width: 1000px) {
    margin: 0 -10px;
    overflow-x: hidden;
    padding: 0 10px;
  }
`;

export const ContentContainer = styled.div`
  @media (min-width: 1001px) {
    margin: 0 auto;
    max-width: 580px;
  }
`;

export const ContentBox = styled.div`
  background: var(--box-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-contrast);
  color: var(--color-text);
  display: flex;
  flex-flow: column nowrap;
  margin: 0 0 20px 0;
  padding: 20px 15px 20px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
`;

export const LeftNavContainer = styled.div`
  @media (min-width: 1001px) {
    position: fixed;
    width: 160px;
  }
`;
