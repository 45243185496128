import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import styled from "styled-components";
import { Form, Formik, ErrorMessage } from "formik";

import {
  FormRow,
  FormRowColumns,
  FormColumn,
  InputFieldLabel,
  InputField,
  InputAutosize,
  InputDatePicker,
  InputInfo,
  FieldClearWrapper,
  FieldClearButton,
} from "../components/FormFields";
import Button from "../components/Button";
import { properDate, properTime, isMobileOS } from "../helpers";

import * as strings from "../constants/strings";
import * as routes from "../constants/routes";

import { fetchEventTypes } from "../actions";

const InputTitle = styled(InputAutosize)`
  font-size: 21px;
  font-weight: 600;
  line-height: 1.05;
`;

const DatePicker = (FieldProps) => {
  const FieldDatePicker = isMobileOS() ? (
    <InputField
      disabled={FieldProps.disabled}
      type="date"
      id={FieldProps.field.name}
      placeholder={strings.EVENT.date_placeholder}
      {...FieldProps.field}
    />
  ) : (
    <InputDatePicker
      autoComplete="off"
      dateFormat={[
        "MMMM d, yyyy",
        "dd.MM",
        "dd.MM.yy",
        "dd.MM.yyyy",
        "d.M",
        "d.M.yy",
        "d.M.yyyy",
        "dd/MM",
        "dd/MM/yy",
        "dd/MM/yyyy",
        "d/M",
        "d/M/yy",
        "d/M/yyyy",
      ]}
      disabled={FieldProps.disabled}
      id={FieldProps.field.name}
      placeholderText={strings.EVENT.date_placeholder}
      selected={FieldProps.form.values.start_date}
      {...FieldProps.field}
      onChange={(option) =>
        FieldProps.form.setFieldValue(FieldProps.field.name, option)
      }
      value={null} // override Formik, ReactDatePicker doesn't like `value`
    />
  );

  return (
    <FieldClearWrapper>
      {FieldDatePicker}
      {FieldProps.field.value && !FieldProps.disabled && (
        <FieldClearButton
          onClick={() =>
            FieldProps.form.setFieldValue(FieldProps.field.name, "")
          }
          tabIndex="-1"
        />
      )}
    </FieldClearWrapper>
  );
};

const TimePicker = (FieldProps) => {
  const FieldTimePicker = isMobileOS() ? (
    <InputField
      disabled={FieldProps.disabled}
      type="time"
      id={FieldProps.field.name}
      placeholder={strings.EVENT.date_placeholder}
      {...FieldProps.field}
    />
  ) : (
    <InputDatePicker
      autoComplete="off"
      dateFormat={"HH:mm"}
      disabled={FieldProps.disabled}
      id={FieldProps.field.name}
      placeholderText={strings.EVENT.time_placeholder}
      showTimeSelect
      showTimeSelectOnly
      timeCaption="Time"
      timeFormat="HH:mm"
      timeIntervals={30}
      selected={FieldProps.form.values[FieldProps.field.name]}
      {...FieldProps.field}
      onChange={(option) =>
        FieldProps.form.setFieldValue(FieldProps.field.name, option)
      }
      value={null} // override Formik, ReactDatePicker doesn't like `value`
    />
  );

  return (
    <FieldClearWrapper>
      {FieldTimePicker}
      {FieldProps.field.value && !FieldProps.disabled && (
        <FieldClearButton
          onClick={() =>
            FieldProps.form.setFieldValue(FieldProps.field.name, "")
          }
          tabIndex="-1"
        />
      )}
    </FieldClearWrapper>
  );
};

const EventForm = (props) => {
  const { eventTypes, event, lock, fetchEventTypes } = props;
  const noEvent = isMobileOS() ? "" : null;

  useEffect(() => {
    fetchEventTypes();
  });

  const handleSubmit = (values) => {
    if (props.view === "edit") {
      props.editEvent(event.id, values);
    } else {
      props.createEvent(values);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();

    const proceedCancel = () => {
      if (props.view === "edit") {
        navigate(`${routes.EVENT_DIR}/${event.id}`);
      } else {
        navigate(`${routes.EVENT_LIST}/`);
      }
    };
    proceedCancel();

    // TODO: prevent data loss when leaving form
    // if (state.edited === true) {
    //   if (window.confirm("Are you sure? Your changes will be lost.")) {
    //     proceedCancel();
    //   }
    // } else {
    //   proceedCancel();
    // }
  };

  return (
    <Formik
      initialValues={{
        kind: event ? event.kind : "",
        name: event ? event.name : "",
        description: event ? event.description : "",
        start_date: event ? properDate(event.start_date) : noEvent,
        start_time: event ? properTime(event.start_time) : noEvent,
        end_time: event ? properTime(event.end_time) : noEvent,
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <>
          <Form>
            <FormRow>
              <InputFieldLabel htmlFor="eventType">
                {strings.EVENT.type}
                <small>{strings.EVENT.required}</small>
              </InputFieldLabel>
              <InputField
                component="select"
                disabled={lock}
                id="eventType"
                name="kind"
                required
              >
                <option value="">{strings.EVENT.type_placeholder}</option>
                {Object.values(eventTypes).map((eventType) => (
                  <option value={eventType.id} key={eventType.id}>
                    {eventType.name}
                  </option>
                ))}
              </InputField>
              <ErrorMessage name="kind" component="div" />
            </FormRow>
            <FormRow>
              <InputFieldLabel htmlFor="eventTitle">
                {strings.EVENT.title}
                <small>{strings.EVENT.required}</small>
              </InputFieldLabel>
              <InputField name="name">
                {({ field }) => (
                  <InputTitle
                    {...field}
                    disabled={lock}
                    id="eventTitle"
                    maxLength={200}
                    placeholder={strings.EVENT.title_placeholder}
                    required
                  />
                )}
              </InputField>
              <ErrorMessage name="name" component="div" />
            </FormRow>
            <FormRow>
              <InputFieldLabel htmlFor="eventDescription">
                {strings.EVENT.description}
              </InputFieldLabel>
              <InputField name="description">
                {({ field }) => (
                  <InputAutosize
                    {...field}
                    disabled={lock}
                    id="eventDescription"
                    maxRows={10}
                    minRows={4}
                    placeholder={strings.EVENT.description_placeholder}
                  />
                )}
              </InputField>
              <InputInfo>
                Supports{" "}
                <a
                  href="https://commonmark.org/help/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Markdown
                </a>
              </InputInfo>
            </FormRow>
            <FormRow>
              <InputFieldLabel htmlFor="start_date">
                {strings.EVENT.date}
              </InputFieldLabel>
              <InputField
                component={DatePicker}
                disabled={lock}
                name="start_date"
              />
            </FormRow>
            <FormRowColumns>
              <FormColumn>
                <InputFieldLabel htmlFor="start_time">
                  {strings.EVENT.time_start}
                </InputFieldLabel>
                <InputField
                  component={TimePicker}
                  disabled={lock}
                  selectsStart
                  name="start_time"
                  placeholder={strings.EVENT.time_placeholder}
                />
              </FormColumn>
              <FormColumn>
                <InputFieldLabel htmlFor="end_time">
                  {strings.EVENT.time_end}
                </InputFieldLabel>
                <InputField
                  component={TimePicker}
                  disabled={lock}
                  name="end_time"
                  selectsEnd
                  placeholder={strings.EVENT.time_placeholder}
                />
              </FormColumn>
            </FormRowColumns>
            <FormRow buttonRow>
              <Button onClick={handleCancel} secondary>
                {strings.EVENT.cancel}
              </Button>
              <Button type="submit" disabled={isSubmitting || lock}>
                {props.view === "edit"
                  ? strings.EVENT.update
                  : strings.EVENT.create}
              </Button>
            </FormRow>
          </Form>
        </>
      )}
    </Formik>
  );
};

EventForm.propTypes = {
  createEvent: PropTypes.func,
  editEvent: PropTypes.func,
  event: PropTypes.object,
  eventTypes: PropTypes.object.isRequired,
  view: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    eventTypes: state.eventTypes,
  };
};

const mapDispatchToProps = {
  fetchEventTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
