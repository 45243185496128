import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Btn = styled.button`
  background: var(--color-cta);
  color: var(--color-button--primary);
  cursor: pointer;
  border: 1px solid var(--color-cta);
  border-radius: var(--border-radius-button);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.1;
  padding: 7px 15px 6px;
  text-transform: uppercase;
  transition: var(--transition-time) opacity;

  &:hover {
    opacity: 0.85;
  }

  ${(props) =>
    props.small &&
    css`
      /* background: var(--color-alert);
      border-color: var(--color-alert); */
      font-size: 12px;
      padding: 3px 8px 2px;
    `};

  ${(props) =>
    props.round &&
    props.small &&
    css`
      align-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      justify-content: center;
      padding: 0;
      width: 26px;
    `};

  ${(props) =>
    props.warning &&
    css`
      background: var(--color-alert);
      border-color: var(--color-alert);
    `};

  ${(props) =>
    props.active &&
    css`
      background: transparent;
      color: var(--color-cta);
      cursor: default;

      &:hover {
        opacity: 1;
      }

      &:focus {
        outline: none;
      }
    `};

  ${(props) =>
    props.secondary &&
    css`
      background: var(--color-secondary);
      border-color: var(--color-secondary);
      color: var(--color-cta--secondary);
      transition: background var(--transition-time),
        border-color var(--transition-time);

      &:hover {
        background: var(--color-secondary-dark);
        border-color: var(--color-secondary-dark);
        opacity: 1;
      }
    `};

  ${(props) =>
    props.attention &&
    css`
      background: var(--brand-primary);
      border-color: var(--brand-primary);
      color: white;
    `};

  ${(props) =>
    props.inverted &&
    css`
      background: transparent;
      color: var(--color-cta);
    `};

  ${(props) =>
    props.warning &&
    props.inverted &&
    css`
      color: var(--color-alert);
      border-color: 1px solid var(--color-alert);
      transition: none;

      &:hover {
        background-color: var(--color-alert);
        border-color: var(--color-alert);
        color: white;
      }
    `};

  ${(props) =>
    props.large &&
    css`
      padding: 9px;
    `};

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5 !important;
    `};

  ${(props) =>
    props.warning &&
    props.inverted &&
    props.disabled &&
    css`
      &:hover {
        background: transparent;
        color: var(--color-alert);
      }
    `};
`;

export const TextButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  line-height: 1;
  padding: 0;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: auto -7.5px 0;

  button {
    flex: 1 0 auto;
    margin: 0 7.5px;
    z-index: 1;
  }
`;

const Button = (props) => {
  return <Btn {...props}>{props.children}</Btn>;
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inverted: PropTypes.bool,
  onClick: PropTypes.func,
  warning: PropTypes.bool,
};

export default Button;
