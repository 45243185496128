import * as ACTION from "./types";

import { fetchMe, fetchUsers } from "./users";
import { captureException } from "../helpers";

export const initialize = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION.INITIALIZING });

    await dispatch(fetchMe());

    // only fetch users if user has been approved
    if (getState().user.is_active) {
      await dispatch(fetchUsers());
    }

    dispatch({
      type: ACTION.READY,
    });
  } catch (error) {
    captureException(error);
  }
};
