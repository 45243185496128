import React from "react";
import PropTypes from "prop-types";

import Placeholder from "../components/Placeholder";

const PagePlaceholder = () => {
  return (
    <Placeholder style={{ height: 250, width: "100%", marginBottom: "20px" }} />
  );
};

PagePlaceholder.propTypes = {
  count: PropTypes.number,
};

export default PagePlaceholder;
