import styled, { css } from "styled-components";

export const FormRow = styled.div`
  margin: 0 0 30px 0;

  &:last-child {
    margin-bottom: 5px;
  }

  ${(props) =>
    props.buttonRow &&
    css`
      display: flex;
      flex-flow: row no-wrap;

      > * {
        flex: 1;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    `};
`;

export const FormRowColumns = styled(FormRow)`
  display: flex;
  flex-flow: row nowrap;
  margin-left: -15px;
  margin-right: -15px;
`;

export const FormColumn = styled.div`
  padding: 0 15px;
  width: 50%;
`;

export const FormRowCompact = styled.div`
  margin: 0 0 10px 0;
`;

export const InputFieldLabel = styled.label`
  align-items: baseline;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 7px 0;

  small {
    font-weight: 400;
    margin-left: auto;
    opacity: 0.5;
  }
`;

export const FieldClearButton = styled.button`
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 16px;
  outline: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  width: 16px;

  &:after {
    background-color: var(--brand-primary);
    border-radius: 50%;
    box-sizing: border-box;
    content: "×";
    cursor: pointer;
    color: var(--box-bg);
    font-size: 12px;
    height: 100%;
    line-height: 1;
    padding: 2px;
    position: absolute;
    right: 0px;
    text-align: center;
    top: 0;
    width: 100%;
  }
`;

export const InputInfo = styled.small`
  color: var(--text-grey--dark);
  display: block;
  font-size: 14px;
  line-height: 1.285;
  margin: 5px 0 10px;

  p,
  ul {
    margin-top: 0;
    margin-bottom: 5px;
  }
`;

export const FieldClearWrapper = styled.div`
  position: relative;
`;
