import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import { navigate } from "@reach/router";

import * as routes from "../constants/routes";
import * as strings from "../constants/strings";
import { CACHE_DURATION } from "../constants";
import {
  setMeta,
  fetchEvents,
  fetchEventsPast,
  refreshEvents,
} from "../actions";

import ScrollToTop from "../components/ScrollToTop";
import { ContentContainer, LeftNavContainer } from "../components/Layout";
import EventItem from "./EventItem";
import EventFilter, { filterEvents } from "./EventFilter";
import EventPlaceholder from "./EventPlaceholder";
import FloatingButton from "../components/FloatingButton";
import Fallback from "../components/Fallback";
import Notice from "../components/Notice";
import Markdown from "../components/Markdown";

const IdeaNotice = styled(Notice)`
  @media (min-width: 1120px) {
    width: 230px;
  }
`;

class EventList extends Component {
  fetchContent = () => {
    switch (this.props.filter) {
      case "past":
        this.props.fetchEventsPast();
        break;
      case "mine":
        this.props.fetchEvents();
        this.props.fetchEventsPast();
        break;
      default:
        this.props.fetchEvents();
        break;
    }
  };

  componentDidMount = () => {
    this.fetchContent();

    this.props.setMeta({
      navActive: "events",
      parent: routes.DASHBOARD,
      title: strings.TITLES.event_list,
    });

    this.refreshList = setInterval(() => {
      if (this.props.filter !== "past") {
        this.props.refreshEvents();
      }
    }, CACHE_DURATION);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.fetchContent();
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.refreshList);
  };

  renderList() {
    const { filter, isFetching, events, user } = this.props;

    if (isFetching) {
      return <EventPlaceholder count={3} container={true} />;
    }

    if (isEmpty(filterEvents(filter, events, user))) {
      let content, func, disabled;

      switch (filter) {
        case "mine":
          content = strings.FALLBACK_EVENTS_MINE;
          func = () => navigate(routes.EVENT_LIST);
          break;
        case "ideas":
          content = strings.FALLBACK_EVENTS_IDEAS;
          disabled = !user.is_in_house;
          func = () => navigate(routes.EVENT_CREATE);
          break;
        case "past":
          content = strings.FALLBACK_EVENTS_PAST;
          disabled = !user.is_in_house;
          func = () => navigate(routes.EVENT_CREATE);
          break;
        default:
          content = strings.FALLBACK_EVENTS_UPCOMING;
          disabled = !user.is_in_house;
          func = () => navigate(routes.EVENT_CREATE);
          break;
      }

      return (
        <Fallback content={content} buttonFunc={func} disabled={disabled} />
      );
    }

    return filterEvents(filter, events, user).map((item) => (
      <EventItem
        event={item}
        key={item.id}
        editMembership={this.props.editMembership}
        view="compact"
      />
    ));
  }

  render() {
    const { user } = this.props;

    return (
      <ScrollToTop force={true}>
        <LeftNavContainer>
          <EventFilter filter={this.props.filter} />
          {this.props.filter === "ideas" && (
            <IdeaNotice>
              <Markdown>{strings.IDEA.explainer}</Markdown>
            </IdeaNotice>
          )}
        </LeftNavContainer>
        <ContentContainer>{this.renderList()}</ContentContainer>
        {user.is_in_house && <FloatingButton />}
      </ScrollToTop>
    );
  }
}

EventList.propTypes = {
  events: PropTypes.array.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  fetchEventsPast: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  refreshEvents: PropTypes.func.isRequired,
  setMeta: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    events: Object.values(state.events.items),
    isFetching: state.events.isFetching,
    user: state.user,
  };
};

const mapDispatchToProps = {
  setMeta,
  fetchEvents,
  fetchEventsPast,
  refreshEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
