import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "@reach/router";
import sortBy from "lodash/sortBy";

import * as routes from "../constants/routes";
import * as strings from "../constants/strings";
import { fetchPages } from "../actions";

import Placeholder from "../components/Placeholder";
import { H2 } from "../components/Headings";

const ListWrapper = styled.ul`
  border-top: 1px solid var(--color-border--subtle);
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  border-bottom: 1px solid var(--color-border--subtle);
  padding: 10px 0;
`;

const PageLink = styled(Link)`
  color: inherit;
  display: block;
  text-decoration: none;

  .icon {
    color: var(--color-cta);
    width: 30px;
  }
`;

const ExternalLink = styled.a`
  color: inherit;
  display: block;
  text-decoration: none;

  .icon {
    color: var(--color-cta);
    width: 30px;
  }
`;

class StaticPageList extends Component {
  componentDidMount = () => {
    this.props.fetchPages();
  };

  renderContent = () => {
    const { pages, isFetching } = this.props;

    if (isFetching) {
      return (
        <>
          {Array(5)
            .fill("")
            .map((_item, i) => (
              <ListItem key={i}>
                <Placeholder style={{ height: "18px", width: "100%" }} />
              </ListItem>
            ))}
        </>
      );
    }

    return (
      <>
        {pages.map((item) => (
          <ListItem key={item.id}>
            {item.type === "external" && (
              <ExternalLink href={item.content} target="_blank" rel="noopener">
                {item.name}
              </ExternalLink>
            )}
            {item.type === "page" && (
              <PageLink to={`${routes.PAGE_DIR}/${item.slug}`}>
                {item.name}
              </PageLink>
            )}
          </ListItem>
        ))}
      </>
    );
  };

  render() {
    return (
      <>
        <H2>{strings.PAGES.title}</H2>
        <ListWrapper>{this.renderContent()}</ListWrapper>
      </>
    );
  }
}

StaticPageList.propTypes = {
  fetchPages: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  pages: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    pages: sortBy(Object.values(state.pages.items), ["position"]),
    isFetching: state.pages.isFetching,
  };
};

const mapDispatchToProps = {
  fetchPages,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaticPageList);
