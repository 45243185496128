import { Field } from "formik";
import styled, { css } from "styled-components";

export const InputField = styled(Field)`
  appearance: none;
  background: var(--box-bg);
  border: 1px solid var(--color-input-border);
  border-radius: var(--border-radius-sm);
  color: var(--color-text);
  display: block;
  height: 40px;
  outline-offset: -2px;
  padding: 10px;
  resize: none;
  -webkit-overflow-scrolling: touch;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    border-color: var(--brand-primary);
    outline: 1px solid var(--brand-primary);
  }

  &::placeholder {
    opacity: 0.5;
  }

  // Fallback for iOS
  &[type="date"] {
    &::placeholder {
      display: none;
    }

    &[value=""]::before {
      content: attr(placeholder);
      opacity: 0.5;
    }
  }

  ${(props) =>
    props.compact &&
    css`
      padding: 5px 10px;
    `};

  ${(props) =>
    props.component === "select" &&
    css`
      &:not([multiple]) {
        background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 320 512'%3e%3cpath fill='currentColor' style='opacity: 0.5' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3e%3c/svg%3e")
          right 10px center no-repeat / 12px auto;
        appearance: none;
        padding: 7px 10px;
      }
    `};
`;

export default InputField;
