import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, navigate } from "@reach/router";
import ResizeObserver from "resize-observer-polyfill";
import { useSpring, animated } from "react-spring";
import useMeasure from "react-use-measure";
import useMedia from "react-use/lib/useMedia";
import Markdown from "../components/Markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as strings from "../constants/strings";
import * as routes from "../constants/routes";
import { scrollToRef } from "../helpers";

import Avatar from "../components/Avatar";
import Button from "../components/Button";

const UserListItemWrapper = styled.li``;

const UserListItemContainer = styled(animated.div)`
  background: var(--box-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-contrast);
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 8px;
  position: relative;
  transform: translate3d(0, 0, 0);
  will-change: padding, height;
`;

const UserAvatar = styled.div``;

const UserContent = styled(animated.div)`
  margin: 0 0 0 10px;
  padding: 18px 0 0 0;
`;

const UserName = styled.h3`
  font-size: 18px;
  margin: 0;
  padding-right: 40px;
`;

const UserDetails = styled(animated.div)`
  overflow: hidden;
  padding: 1px 10px 1px 0;
  will-change: transform, opacity, height;

  p,
  ul {
    font-size: 14px;
    line-height: 1.35;
    list-style-type: disc;
    margin: 0 0 0.75rem;

    @media (min-width: 600px) {
      font-size: 15px;
      padding-right: 18px;
    }
  }

  li {
    line-height: inherit;
    margin-top: 0;
  }
`;

const UserDetailsHeader = styled.h4`
  font-size: 16px;
  line-height: 1.35;
  margin: 0 0 5px;
`;

const UserDetailsItem = styled.div`
  padding-bottom: 10px;

  &:last-child {
    padding-bottom: 0;
  }
`;

const UserLink = styled(animated(Link))`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const UserItemClose = styled(animated(Button))`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 15px;
`;

const UserListItem = ({ user, active: outerIsActive, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const scrollRef = useRef(null);
  const stickyHeaderBreakpoint = useMedia(`(min-width: 1001px)`);

  const [contentHeight, setContentHeight] = useState("0px");
  const [ref, { height }] = useMeasure({ polyfill: ResizeObserver });

  const {
    height: detailsHeight,
    marginTop: detailsMarginTop,
    opacity: detailsOpacity,
    padding: cardPadding,
    transform: closeButtonTransform,
  } = useSpring({
    config: {
      friction: 30,
      tension: 300,
    },
    height: isOpen ? `${contentHeight}px` : "0px",
    marginTop: isOpen ? 20 : 0,
    opacity: isOpen ? 1 : 0,
    onFrame: () =>
      isOpen && scrollToRef(scrollRef, stickyHeaderBreakpoint ? 68 : 9),
    padding: `${isOpen ? 18 : 9}px 9px ${isOpen ? 36 : 9}px 9px`,
    transform: `translateY(${isOpen ? 13 : 0}px)`,
  });

  useEffect(() => {
    setIsOpen(outerIsActive);
  }, [outerIsActive]);

  useEffect(() => {
    //Sets initial height
    setContentHeight(height);
  }, [height]);

  return (
    <UserListItemWrapper {...props} ref={scrollRef}>
      <UserListItemContainer
        style={{
          padding: cardPadding,
        }}
      >
        <UserAvatar>
          <Avatar userId={user.id} size="l" />
        </UserAvatar>
        <UserContent>
          <UserName>{`${user.first_name} ${user.last_name}`}</UserName>
          <UserDetails
            style={{
              height: detailsHeight,
              marginTop: detailsMarginTop,
              opacity: detailsOpacity,
            }}
          >
            <div ref={ref}>
              {user.questions.map((item, i) => (
                <UserDetailsItem key={i}>
                  <UserDetailsHeader>{item.title}</UserDetailsHeader>
                  {item.answer !== "" ? (
                    <Markdown>{item.answer}</Markdown>
                  ) : (
                    <p style={{ opacity: 0.3 }}>
                      {`${user.first_name} ${strings.USER.answerMissing}`}
                    </p>
                  )}
                </UserDetailsItem>
              ))}
            </div>
          </UserDetails>
        </UserContent>
        <UserLink
          to={`${routes.USERS}/${user.id}`}
          style={{
            visibility: detailsOpacity.interpolate((o) =>
              o === 0 ? "visible" : "hidden"
            ),
          }}
        />

        <UserItemClose
          onClick={() => navigate(`${routes.USERS}/all`)}
          round
          small
          inverted
          title={strings.USERS.close}
          style={{
            opacity: detailsOpacity,
            transform: closeButtonTransform,
            visibility: detailsOpacity.interpolate((o) =>
              o === 0 ? "hidden" : "visible"
            ),
          }}
        >
          <FontAwesomeIcon icon={["fas", "times"]} />
        </UserItemClose>
      </UserListItemContainer>
    </UserListItemWrapper>
  );
};

UserListItem.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserListItem;
