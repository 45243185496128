import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import sortBy from "lodash/sortBy";

import * as strings from "../constants/strings";
import { fetchResources } from "../actions";

import ResourcePlaceholder from "./ResourcePlaceholder";
import { WidgetTitle } from "../components/DashboardWidgetTitle";

const ListWrapper = styled.ul`
  display: flex;
  flex-flow: row wrap;
  margin: 0 -7.5px 40px;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  margin: 0 7.5px 15px;
  width: calc(50% - 15px);

  @media (min-width: 540px) {
    width: calc(33.33333333% - 15px);
  }

  @media (min-width: 1001px) {
    margin-bottom: 10px;
    width: calc(100% - 15px);
  }

  span {
    display: flex;
    flex-flow: column nowrap;
  }

  strong {
    font-size: 14px;
  }

  small {
    color: var(--text-grey);
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.2;
  }

  .link {
    align-items: center;
    background: var(--box-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-miniscule);
    color: inherit;
    display: flex;
    height: 100%;
    flex-flow: row nowrap;
    min-height: 76px;
    padding: 15px 10px;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
    transition: all var(--transition-time);

    &:hover {
      transform: scale(0.97);
    }
  }
`;

const ResourceIcon = styled.span`
  svg {
    align-self: center;
    height: 26px;
    font-size: 26px;
    margin: 0 10px 0 5px;
    width: 26px !important;
  }
`;

class ResourceList extends Component {
  componentDidMount = () => {
    this.props.fetchResources();
  };

  renderContent = () => {
    const { resources, isFetching } = this.props;

    if (isFetching) {
      return (
        <>
          {Array(5)
            .fill("")
            .map((item, i) => (
              <ListItem key={i}>
                <span className="link">
                  <ResourcePlaceholder />
                </span>
              </ListItem>
            ))}
        </>
      );
    }

    return (
      <>
        {resources.map((item) => (
          <ListItem key={item.id}>
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <ResourceIcon
                style={item.color && { color: item.color }}
                dangerouslySetInnerHTML={{ __html: item.icon }}
              />
              <span>
                <strong>{item.name}</strong>
                {item.short_description && (
                  <small>{item.short_description}</small>
                )}
              </span>
            </a>
          </ListItem>
        ))}
        {/* <ListItem>
          <Link to={routes.PAGE_ABOUT} className="link">
            <FontAwesomeIcon icon={["fas", "info-circle"]} className="icon" />
            <span>
              <strong>About this app</strong>
              <small>Short description of this resource</small>
            </span>
          </Link>
        </ListItem> */}
      </>
    );
  };

  render() {
    return (
      <div>
        <WidgetTitle>{strings.RESOURCES.title}</WidgetTitle>
        <ListWrapper>{this.renderContent()}</ListWrapper>
      </div>
    );
  }
}

ResourceList.propTypes = {
  fetchResources: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  resources: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    resources: sortBy(state.resources.items, ["position"]),
    isFetching: state.resources.isFetching,
  };
};

const mapDispatchToProps = {
  fetchResources,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceList);
