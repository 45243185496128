import React from "react";
import styled, { css } from "styled-components";
import {
  format,
  parse,
  isToday,
  isPast,
  isThisMinute,
  isFuture,
} from "date-fns";
import { getTime } from "../helpers";

import * as strings from "../constants/strings";

const EventDateContainer = styled.div`
  align-items: flex-start;
  display: inline-flex;
  flex-flow: column;
`;

const EventBox = styled.div`
  border: 1px solid var(--color-border--light);
  border-radius: var(--border-radius-sm);
  display: inline-flex;
  flex-flow: row nowrap;
  position: relative;

  ${(props) =>
    props.compact &&
    css`
      flex-flow: column nowrap;
      text-align: center;
      width: 42px;
    `};
`;

const EventDate = styled.div`
  display: flex;
  flex-flow: column nowrap;
  line-height: 1;
  font-weight: 600;
  padding: 4px 4px 2px;
  text-align: center;
  text-transform: uppercase;
`;

const Day = styled.div`
  font-size: 24px;
`;

const Month = styled.div`
  font-size: 12px;
`;

const EventTime = styled.div`
  align-items: center;
  border-left: 1px solid var(--color-border--light);
  display: flex;
  font-size: 15px;
  justify-content: center;
  line-height: 1;
  font-weight: 600;
  flex: 1;
  padding: 2px 10px;

  ${(props) =>
    props.compact &&
    css`
      border-top: 1px solid var(--color-border--light);
      border-left: none;
      font-size: 12px;
      text-align: center;
      padding: 2px 0;
    `};

  svg {
    color: var(--brand-primary);
    font-size: 12px;
    vertical-align: 0;
    margin-right: 5px;
    top: 2px;
    position: relative;
    width: 14px !important;
  }
`;

const CurrentEvent = styled.div`
  background-color: var(--brand-primary);
  border-radius: var(--border-radius-sm);
  color: var(--box-bg);
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
  margin-top: 5px;
  padding: 5px 0 4px;
  text-align: center;
  text-transform: uppercase;
  top: 100%;
  width: 100%;

  ${(props) =>
    props.compact &&
    css`
      font-size: 10px;
      margin-top: 3px;
      padding: 3px 0 2px;
    `};
`;

const EventDateTime = (props) => {
  const { compact } = props;
  const { start_date, start_time, end_time } = props.date;
  const month = format(new Date(start_date), "MMM");
  const day = format(new Date(start_date), "D");
  const startTime = format(getTime(start_time), "HH:mm");
  const endTime = format(getTime(end_time), "HH:mm");

  const current = () => {
    const item_date = parse(start_date);
    const item_start_time = start_time ? getTime(start_time) : null;
    const item_end_time = end_time ? getTime(end_time) : null;

    return (
      isToday(item_date) &&
      (isThisMinute(item_start_time) || isPast(item_start_time)) &&
      isFuture(item_end_time)
    );
  };

  return (
    <EventDateContainer {...props}>
      <EventBox compact={props.compact} current={current()}>
        <EventDate>
          <Month>{month}</Month>
          <Day>{day}</Day>
        </EventDate>

        {start_time && (
          <EventTime compact={props.compact}>
            {startTime} {start_time && end_time && !compact && `- ${endTime}`}
          </EventTime>
        )}
      </EventBox>

      {current() && (
        <CurrentEvent compact={props.compact}>
          {props.compact
            ? strings.EVENT.current_compact
            : strings.EVENT.current}
        </CurrentEvent>
      )}
    </EventDateContainer>
  );
};

export default EventDateTime;
