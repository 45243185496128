import { generateCacheTTL } from "redux-cache";
import mapKeys from "lodash/mapKeys";
import omit from "lodash/omit";

import * as ACTION from "../actions/types";
import {
  CACHE_EVENTS,
  CACHE_EVENTS_PAST,
  CACHE_DURATION,
  CACHE_DURATION_LONG,
} from "../constants";

const initialState = {
  [CACHE_EVENTS]: null,
  [CACHE_EVENTS_PAST]: null,
  items: {},
  isFetching: true,
  error: false,
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.EVENTS_FETCH_REQUEST:
    case ACTION.EVENTS_PAST_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case ACTION.EVENTS_FETCH_SUCCESS:
    case ACTION.EVENTS_REFRESHED:
      return {
        ...state,
        [CACHE_EVENTS]: generateCacheTTL(CACHE_DURATION),
        isFetching: false,
        items: { ...state.items, ...mapKeys(action.payload, "id") },
        error: false,
      };
    case ACTION.EVENTS_PAST_FETCH_SUCCESS:
      return {
        ...state,
        [CACHE_EVENTS_PAST]: generateCacheTTL(CACHE_DURATION_LONG),
        isFetching: false,
        items: { ...state.items, ...mapKeys(action.payload, "id") },
        error: false,
      };
    case ACTION.EVENTS_FETCH_ERROR:
    case ACTION.EVENTS_PAST_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case ACTION.EVENT_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case ACTION.EVENT_FETCH_SUCCESS:
    case ACTION.EVENT_CREATE_SUCCESS:
    case ACTION.EVENT_UPDATE_SUCCESS:
    case ACTION.EVENT_REFRESHED:
      return {
        ...state,
        isFetching: false,
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
        error: false,
      };
    case ACTION.EVENT_LOCK_SUCCESS:
    case ACTION.EVENT_LOCK_DENIED:
      return {
        ...state,
        lock: action.payload,
      };
    case ACTION.EVENT_RELEASE_SUCCESS:
    case ACTION.EVENT_LOCK_CLEAR_LOCAL:
      return {
        ...state,
        lock: undefined,
      };
    case ACTION.EVENT_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        items: omit(state.items, action.payload),
      };
    case ACTION.EVENT_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case ACTION.EVENT_PARTICIPANT_REQUEST:
    case ACTION.EVENT_LEAD_REQUEST:
    case ACTION.EVENT_LEAVE_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case ACTION.EVENT_PARTICIPANT_SUCCESS:
    case ACTION.EVENT_LEAD_SUCCESS:
    case ACTION.EVENT_LEAVE_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
        isRequesting: false,
      };
    default:
      return state;
  }
};

export default eventReducer;
