// import React from "react";
import styled from "styled-components";

const ActionsSecondary = styled.div`
  display: flex;
  flex-basis: 100%;
  font-size: 15px;
  justify-content: center;
  margin: 30px 0 0;
`;

export default ActionsSecondary;
