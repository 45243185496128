import { PAGE_META_SET } from "../actions/types";

const initialState = {
  titleBrowser: `${process.env.REACT_APP_COMMUNITY_NAME}`,
  title: "",
  parent: "",
};

const metaReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_META_SET:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default metaReducer;
