import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useField } from "formik";

import { InputInfo, InputFieldLabel } from "./common";

const CheckboxContainer = styled.div`
  border-bottom: 1px solid #eee;
  margin: 0 0 12px;
  padding: 4px 42px 14px 0;
  position: relative;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  ${(props) =>
    props.disabled &&
    css`
      ${CheckboxFaux} {
        cursor: default;
        opacity: 0.5;
      }
    `}
`;

const CheckboxField = styled.input`
  right: 10px;
  margin: 0 0 0 10px;
  position: absolute;
  opacity: 0;
`;

export const CheckboxFaux = styled.div`
  cursor: pointer;
  height: 24px;
  right: 0;
  top: 0;
  position: absolute;
  width: 38px;
  background: #ddd;
  border-radius: 30px;

  &::after {
    border-radius: 50%;
    background: #fff;
    height: 20px;
    width: 20px;
    margin: 0;
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 250ms, background 250ms;
  }

  ${({ checked }) =>
    checked &&
    css`
      background: var(--color-cta);

      &::after {
        transform: translateX(14px);
      }
    `}

  svg {
    height: 18px;
    width: 18px;
  }
`;

const CheckboxLabel = styled(InputFieldLabel)`
  display: flex;
  flex-flow: column;
  margin: 0;

  ${InputInfo} {
    margin: 5px 0 0;
  }
`;

const CheckboxSlider = ({
  children,
  className,
  disabled,
  hideRequired,
  required,
  description,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <CheckboxContainer className={className} disabled={disabled}>
      <CheckboxLabel>
        {children}
        {required && !hideRequired && "*"}
        {description && <InputInfo>{description}</InputInfo>}
        <CheckboxField
          type="checkbox"
          disabled={disabled}
          {...field}
          {...props}
        />
        <CheckboxFaux
          aria-hidden="true"
          checked={field.checked}
          error={meta.touched && meta.error ? "true" : undefined}
        ></CheckboxFaux>
      </CheckboxLabel>
      {/* {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null} */}
    </CheckboxContainer>
  );
};

CheckboxSlider.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  hideRequired: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default CheckboxSlider;
