import React from "react";
import styled from "styled-components";

import * as brandData from "../branding/brandData";

export const LandingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  /* padding: 20px; */
  text-align: center;

  p {
    line-height: 1.3;
  }
`;

export const LogoContainer = styled.div`
  align-items: center;
  background: var(--box-bg);
  color: var(--brand-logo-theme);
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  padding: 20px;
  width: 100%;

  svg {
    max-width: 240px;
    max-height: 100%;
  }
`;

export const LandingContent = styled.div`
  align-items: center;
  display: flex;
  flex: 3 0 auto;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  max-width: 500px;
  padding: 20px;
`;

export const LandingText = styled.div`
  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;

    @media (min-width: 1001px) {
      font-size: 36px;
    }
  }

  svg {
    color: var(--brand-primary);
    margin-bottom: 20px;

    @media (min-width: 1001px) {
      font-size: 30px;
    }
  }

  p {
    color: var(--text-grey);

    @media (min-width: 1001px) {
      font-size: 18px;
    }
  }
`;

const Landing = (props) => {
  return (
    <LandingContainer>
      <LogoContainer
        dangerouslySetInnerHTML={{ __html: brandData.LOGO.company }}
      />
      <LandingContent>{props.children}</LandingContent>
    </LandingContainer>
  );
};

export default Landing;
