import React from "react";
import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { cacheEnhancer } from "redux-cache";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import App from "./App";
import reducers from "./reducers";
// import checkTokenExpirationMiddleware from "./middleware/checkToken";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "./components/ErrorBoundary";
import loadIcons from "./constants/icons";

import "normalize.css/normalize.css";
import "./index.css";

Sentry.init({
  dsn: "https://d55e50888178432592a43717d7dadc48@sentry.io/1511547",
  environment: window.location.hostname,
});

// Fix vw/vh/vmin/vmax support in Mobile Safari
require("viewport-units-buggyfill").init();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk), cacheEnhancer())
);

// Load FontAwesome library
loadIcons();

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
