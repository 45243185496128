import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

const InputAutosize = styled(TextareaAutosize)`
  appearance: none;
  background: var(--box-bg);
  border: 1px solid var(--color-input-border);
  border-radius: var(--border-radius-sm);
  color: var(--color-text);
  display: block;
  outline-offset: -2px;
  padding: 10px;
  resize: none;
  -webkit-overflow-scrolling: touch;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    border-color: var(--brand-primary);
    outline: 1px solid var(--brand-primary);
  }

  &::placeholder {
    opacity: 0.5;
  }
`;

export default InputAutosize;
