import React from "react";
import styled from "styled-components";

import Spinner from "../icons/spinner.svg";

import * as strings from "../constants/strings";

const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: center;
  font-weight: 700;
  padding: 10px;
  text-align: center;
`;

const Loading = () => {
  return (
    <LoadingContainer>
      <img src={Spinner} alt="" />
      {strings.LOADING.fetching_data}
    </LoadingContainer>
  );
};

export default Loading;
