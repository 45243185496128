import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as routes from "../constants/routes";
import * as strings from "../constants/strings";

import {
  setMeta,
  fetchEvent,
  editEvent,
  lockEvent,
  clearLocalLock,
  releaseEvent,
  deleteEvent,
} from "../actions";

import EventForm from "./EventForm";
import { ContentContainer } from "../components/Layout";
import Notice from "../components/Notice";
import Error from "../views/Error";
import { EventContainer } from "./EventItem";
import Placeholder from "../components/Placeholder";

import Button from "../components/Button";
import Splitter from "../components/Splitter";
import ActionsSecondary from "../components/ActionsSecondary";

const EventEdit = (props) => {
  const [disableRelease, setDisableRelease] = useState(false);
  const {
    clearLocalLock,
    deleteEvent,
    editEvent,
    event,
    fetchEvent,
    isFetching,
    itemId,
    lock,
    lockEvent,
    setMeta,
    releaseEvent,
    user,
  } = props;
  const locked = lock?.status === "error";

  useEffect(() => {
    setMeta({
      navActive: "events",
      parent: `${routes.EVENT_DIR}/${itemId}`,
      title: strings.TITLES.event_edit,
    });
  }, [itemId, setMeta]);

  useEffect(() => {
    fetchEvent(itemId);
    console.log("event fetched");
  }, [fetchEvent, itemId]);

  useEffect(() => {
    lockEvent(itemId);
    console.log("event locked");
  }, [lockEvent, itemId]);

  useEffect(() => {
    return () => {
      if (lock?.status === "success" && !disableRelease) {
        releaseEvent(itemId);
        console.log("event released");
      }
      if (lock?.status === "error") {
        clearLocalLock();
        console.log("local lock cleared");
      }
    };
  }, [clearLocalLock, disableRelease, itemId, lock, releaseEvent]);

  const execDelete = () => {
    if (window.confirm(strings.EVENT.delete_confirm)) {
      setDisableRelease(true);
      deleteEvent(itemId);
    }
  };

  const renderContent = () => {
    if (isFetching || !lock) {
      return <Placeholder height={400} style={{ marginBottom: "20px" }} />;
    }

    if (!event) {
      return <Error content={strings.ERROR_EVENT_404} />;
    }

    return (
      <>
        <EventContainer>
          {locked && (
            <Notice type="error" sticky>
              {strings.EVENT.locked_by}
              <b>
                {lock.locked_by.first_name} {lock.locked_by.last_name}
              </b>
              {strings.EVENT.locked_try_again}
            </Notice>
          )}

          <EventForm
            event={event}
            view="edit"
            editEvent={editEvent}
            lock={locked || !user.is_in_house}
          />
        </EventContainer>
        <Splitter>other actions</Splitter>
        <ActionsSecondary>
          <Button
            disabled={locked || !user.is_in_house}
            onClick={execDelete}
            warning
            inverted
          >
            {strings.EVENT.delete}
          </Button>
        </ActionsSecondary>
      </>
    );
  };

  return <ContentContainer>{renderContent()}</ContentContainer>;
};

EventEdit.propTypes = {
  clearLocalLock: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  editEvent: PropTypes.func.isRequired,
  event: PropTypes.object,
  fetchEvent: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  lock: PropTypes.object,
  lockEvent: PropTypes.func.isRequired,
  releaseEvent: PropTypes.func.isRequired,
  setMeta: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    event: state.events.items[ownProps.itemId],
    lock: state.events.lock,
    isFetching: state.events.isFetching,
    user: state.user,
  };
};

const mapDispatchToProps = {
  setMeta,
  fetchEvent,
  lockEvent,
  releaseEvent,
  clearLocalLock,
  editEvent,
  deleteEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventEdit);
