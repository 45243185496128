import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 32px;
  margin: 0 0 15px;
`;

export const H2 = styled.h2`
  font-size: 16px;
  margin: 0 0 15px;
`;

export const DialogHeading = styled.h2`
  font-size: 24px;
  margin: 0 0 15px;
`;
