import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "./Button";

import * as strings from "../constants/strings";

const RemoveButton = styled(Button)`
  /* font-size: 12px; */
`;

const JoinButton = (props) => {
  const joinStates = {
    isEmpty: strings.EVENT.membership.me_join,
    isEmptyIdea: strings.EVENT.membership.me_join_idea,
    isNotParticipant: strings.EVENT.membership.me_join,
    isNotParticipantIdea: strings.EVENT.membership.me_join_idea,
    isParticipant: strings.EVENT.membership.me_joined,
    isParticipantIdea: strings.EVENT.membership.me_joined_idea,
    isLead: strings.EVENT.membership.me_leading,
  };
  const removeStates = {
    isParticipant: strings.EVENT.membership.me_remove,
    isParticipantIdea: strings.EVENT.membership.me_remove_idea,
    isLead: strings.EVENT.membership.me_remove,
  };
  const { joinEvent, leaveEvent, membership, view } = props;

  return (
    <>
      {(membership === "isLead" ||
        membership === "isParticipant" ||
        membership === "isParticipantIdea") && (
        <>
          {view === "details" && (
            <RemoveButton onClick={leaveEvent} warning inverted>
              {removeStates[membership]}
            </RemoveButton>
          )}
          {view === "compact" && (
            <Button active>{joinStates[membership]}</Button>
          )}
        </>
      )}
      {(membership === "isEmpty" ||
        membership === "isEmptyIdea" ||
        membership === "isNotParticipant" ||
        membership === "isNotParticipantIdea") && (
        <Button onClick={joinEvent} disabled={props.disabled}>
          {joinStates[membership]}
        </Button>
      )}
    </>
  );
};

JoinButton.propTypes = {
  disabled: PropTypes.bool,
  joinEvent: PropTypes.func.isRequired,
  leaveEvent: PropTypes.func.isRequired,
  membership: PropTypes.string.isRequired,
  view: PropTypes.string,
};

export default JoinButton;
