import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Link } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as strings from "../constants/strings";

const Navbar = styled.nav`
  background: var(--header-bg);
  border-top: 1px solid var(--header-border);
  bottom: 0;
  color: var(--brand-header-color);
  height: calc(var(--navbar-height) + env(safe-area-inset-bottom));
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;

  @supports (backdrop-filter: blur(10px)) {
    background: var(--header-bg--transparent);
    backdrop-filter: blur(10px);
  }

  @media (min-width: 1001px) {
    background: transparent;
    bottom: auto;
    border: none;
    flex: 1 0 auto;
    height: 100%;
    letter-spacing: 0.025em;
    max-width: 420px;
    margin: 0 auto;
    position: relative;
    top: 0;
    width: auto;
    text-transform: uppercase;

    @supports (backdrop-filter: blur(10px)) {
      backdrop-filter: none;
    }
  }
`;

const Nav = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  height: calc(100% - env(safe-area-inset-bottom));
  justify-content: stretch;
  list-style: none;
  padding: 0;
  margin: 0;

  @media (min-width: 1001px) {
    height: 100%;
  }
`;

const NavItem = styled.li`
  color: var(--text-grey--dark);
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0;
  padding: 0;

  @media (min-width: 1001px) {
    margin: 0 10px;
  }

  ${(props) =>
    props.active &&
    css`
      color: var(--color-cta);

      @media (min-width: 1001px) {
        color: var(--color-text);
      }
    `};
`;

const NavLink = styled(Link)`
  align-items: center;
  border-bottom: 3px solid transparent;
  color: inherit;
  display: flex;
  flex-flow: column nowrap;
  font-size: 21px;
  font-weight: 600;
  justify-content: center;
  padding: 5px 10px;
  text-decoration: none;

  @media (min-width: 1001px) {
    flex-flow: row nowrap;

    svg {
      display: none;
    }
  }

  ${(props) =>
    props.active &&
    css`
      color: var(--color-cta);

      @media (min-width: 1001px) {
        color: var(--color-text);
        border-bottom-color: var(--color-cta);
      }
    `};

  span {
    margin-top: 5px;
    font-size: 12px;

    @media (min-width: 1001px) {
      margin: 0;
      font-size: 15px;
    }
  }
`;

const Navigation = ({ navActive }) => {
  const items = strings.NAVIGATION;

  const renderNavItems = () => {
    return items.map((item) => (
      <NavItem active={navActive === item.active ? 1 : 0} key={item.active}>
        <NavLink active={navActive === item.active ? 1 : 0} to={item.link}>
          <FontAwesomeIcon
            icon={[navActive === item.active ? "fas" : "fal", item.icon]}
          />
          <span>{item.title}</span>
        </NavLink>
      </NavItem>
    ));
  };

  return (
    <Navbar>
      <Nav>{renderNavItems()}</Nav>
    </Navbar>
  );
};

Navigation.propTypes = {
  navActive: PropTypes.string,
};

const mapStateToProps = (state) => ({
  navActive: state.meta.navActive,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
