import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";

import * as routes from "../constants/routes";

const FloatingBtn = styled(Link)`
  align-items: center;
  background: var(--brand-primary);
  border-radius: 50%;
  border: none;
  bottom: calc(90px + env(safe-area-inset-bottom));
  box-shadow: var(--box-shadow-large);
  color: var(--box-bg);
  display: flex;
  font-weight: bold;
  font-size: 60px;
  height: 60px;
  justify-content: center;
  line-height: 0.8;
  position: fixed;
  text-decoration: none;
  right: calc(30px + env(safe-area-inset-right));
  width: 60px;
  z-index: 10;

  &:hover {
    background: var(--brand-primary--hover);
  }

  @media (min-width: 1001px) {
    bottom: calc(30px + env(safe-area-inset-bottom));
  }

  span {
    position: relative;
    top: -3px;
  }
`;

const FloatingButton = () => {
  return (
    <FloatingBtn to={routes.EVENT_CREATE}>
      <span>+</span>
    </FloatingBtn>
  );
};

export default FloatingButton;
