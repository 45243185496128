import styled, { css } from "styled-components";
import { DialogOverlay, DialogContent } from "@reach/dialog";

import "@reach/dialog/styles.css";

import Button, { ButtonRow } from "./Button";

export const LightboxOverlay = styled(DialogOverlay)`
  -webkit-overflow-scrolling: touch;
  z-index: 10;
`;

export const LightboxContent = styled(DialogContent)`
  background: var(--box-bg) !important;
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-large);
  display: flex;
  flex-flow: column nowrap;
  margin: 10px auto !important;
  min-width: 300px;
  width: calc(100vw - 20px) !important;

  ${(props) =>
    props.size === "sm" &&
    css`
      max-width: 400px;

      ${LightboxCloseContainer} {
        max-width: 340px;
      }
    `};

  ${(props) =>
    props.size === "md" &&
    css`
      max-width: 600px;

      ${LightboxCloseContainer} {
        max-width: 540px;
      }
    `};
`;

export const LightboxButtonRow = styled(ButtonRow)`
  margin-top: 10px;
`;

export const LightboxScroll = styled.div`
  &:after {
    content: "";
    bottom: 10px;
    position: absolute;
  }
`;

export const LightboxCloseContainer = styled.div`
  height: 20px;
  left: 50vw;
  margin: 0 auto;
  min-width: 240px;
  position: absolute;
  top: 10px;
  transform: translateX(-50%);
  width: calc(100vw - 80px);

  ${(props) =>
    props.sticky &&
    css`
      position: fixed;
    `};
`;

export const LightboxClose = styled(Button)`
  background: var(--box-bg);
  position: absolute;
  right: -8px;
  top: 20px;
`;
