import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "./Button";
import Markdown from "./Markdown";

const FallbackContainer = styled.div`
  align-items: center;
  background: var(--box-bg);
  border-radius: var(--border-radius);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-bottom: 20px;
  /* min-height: 100px; */
  padding: 50px 10px;
`;

const FallbackHeader = styled.h2`
  font-size: 21px;
  margin: 0 0 10px 0;
  text-align: center;

  svg {
    color: var(--brand-primary);
    margin-right: 10px;
  }
`;

const FallbackText = styled.div`
  margin: 0 0 10px;
  max-width: 320px;
  text-align: center;

  p {
    margin: 0 0 10px;
    line-height: 1.333333;
  }
`;

const Fallback = (props) => {
  const { icon, header, text, buttonText } = props.content;

  return (
    <FallbackContainer {...props}>
      <FallbackHeader>
        {icon && <FontAwesomeIcon icon={["fas", icon]} />}
        {header}
      </FallbackHeader>
      {text && (
        <FallbackText>
          <Markdown>{text}</Markdown>
        </FallbackText>
      )}
      {buttonText && props.buttonFunc && (
        <Button onClick={props.buttonFunc} disabled={props.disabled}>
          {buttonText}
        </Button>
      )}
    </FallbackContainer>
  );
};

Fallback.propTypes = {
  content: PropTypes.object.isRequired,
  buttonFunc: PropTypes.func.isRequired,
};

export default Fallback;
