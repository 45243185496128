import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialIconContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const SocialIcon = styled.a`
  align-items: center;
  background: red;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  margin: 0 4px;
  transition: var(--transition-time) opacity;
  height: 45px;
  width: 45px;

  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `};

  &:hover {
    opacity: 0.85;
  }
`;

const SocialIcons = (props) => {
  return (
    <SocialIconContainer>
      {props.socialLinks.map((item) => (
        <SocialIcon
          key={item.name}
          title={item.name}
          href={item.url}
          color={item.color}
        >
          {item.type === "brand" ? (
            <FontAwesomeIcon icon={["fab", item.icon]} />
          ) : (
            <FontAwesomeIcon icon={["fas", item.icon]} />
          )}
        </SocialIcon>
      ))}
    </SocialIconContainer>
  );
};

SocialIcons.propTypes = {
  socialLinks: PropTypes.array.isRequired,
};

export default SocialIcons;
