import { combineReducers } from "redux";

import authReducer from "./authReducer";
import metaReducer from "./metaReducer";
import eventReducer from "./eventReducer";
import eventTypesReducer from "./eventTypesReducer";
import userReducer from "./userReducer";
import usersReducer from "./usersReducer";
import resourcesReducer from "./resourcesReducer";
import pageReducer from "./pageReducer";

const appReducer = combineReducers({
  auth: authReducer,
  meta: metaReducer,
  events: eventReducer,
  eventTypes: eventTypesReducer,
  user: userReducer,
  users: usersReducer,
  resources: resourcesReducer,
  pages: pageReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    // reset entire store to initial values on logout
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
