import React from "react";
import styled, { css } from "styled-components";

const Info = styled.div`
  background: var(--notice-background);
  border: 1px solid var(--notice-border);
  border-radius: var(--border-radius-sm);
  color: var(--notice-color);
  display: flex;
  font-size: 14px;
  flex-flow: column nowrap;
  margin-bottom: 30px;
  padding: 10px;
  line-height: 1.25;
  z-index: 1;

  p:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.sticky &&
    css`
      @supports (position: sticky) {
        position: sticky;
        top: 15px;

        @media (min-width: 1001px) {
          top: 75px;
        }
      }
    `};
`;

const InfoTitle = styled.h3`
  font-size: 14px;
  margin: 0 0 5px;
  text-transform: uppercase;
`;

const Notice = (props) => {
  const { title } = props;
  return (
    <Info {...props}>
      {title && <InfoTitle>{title}</InfoTitle>}
      <span>{props.children}</span>
    </Info>
  );
};

export default Notice;
