import { useEffect } from "react";
import { useLocation } from "@reach/router";

const ScrollToTop = ({ force, children }) => {
  const location = useLocation();
  useEffect(() => {
    if (force) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, force, location.path, location]);
  return children;
};

export default ScrollToTop;
