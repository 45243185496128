import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Link } from "@reach/router";
import useWindowScroll from "react-use/lib/useWindowScroll";

import * as routes from "../constants/routes";
import * as brandData from "../branding/brandData";

import Avatar from "./Avatar";
import Navigation from "./Navigation";

const HeaderBar = styled.header`
  background: var(--header-bg);
  border-bottom: 1px solid var(--header-border);
  box-shadow: none;
  color: var(--brand-header-color);
  display: flex;
  flex-flow: row nowrap;
  transition: box-shadow var(--transition-time);
  height: var(--navbar-height);
  position: absolute;
  width: 100%;
  z-index: 9;

  @media (min-width: 1001px) {
    position: fixed;
  }

  @supports (backdrop-filter: blur(10px)) {
    @media (min-width: 1001px) {
      background: var(--header-bg--transparent);
      backdrop-filter: blur(10px);
    }
  }

  a {
    color: inherit;
  }

  ${(props) =>
    props.scrolled &&
    css`
      @media (min-width: 1001px) {
        box-shadow: var(--box-shadow-small);
      }
    `};
`;

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: var(--app-width-xs);
  padding: 10px 5px;
  width: 100%;

  @media (min-width: 1001px) {
    max-width: var(--app-width-lg);
    padding: 0 15px;
  }
`;

const ProfileLink = styled(Link)`
  margin: 0 0 0 auto;
  z-index: 1;

  @media (min-width: 1001px) {
    margin: 0;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
`;

const Logo = styled.div`
  display: flex;
  padding: 10px 5px;

  svg {
    color: var(--brand-logo-theme);
    height: var(--signet-height);
    width: auto;
  }
`;

const Header = (props) => {
  const { y } = useWindowScroll();

  return (
    <HeaderBar scrolled={y > 0}>
      <HeaderWrapper>
        <Left>
          <Link to={routes.DASHBOARD}>
            <Logo
              dangerouslySetInnerHTML={{
                __html: brandData.LOGO.signet,
              }}
            />
          </Link>
        </Left>
        <Navigation />
        <ProfileLink to={routes.PROFILE}>
          <Avatar userId={props.user.id} size="sm" />
        </ProfileLink>
      </HeaderWrapper>
    </HeaderBar>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    title: state.meta.title,
    parent: state.meta.parent,
  };
};

export default connect(mapStateToProps)(Header);
