import React from "react";
import PropTypes from "prop-types";

import { EventContainer } from "./EventItem";
import Placeholder from "../components/Placeholder";

const EventPlaceholder = (props) => {
  let placeholder = [];
  const count = props.count ? props.count : 1;
  for (let i = 0; i < count; i++) {
    placeholder.push(
      <EventContainer key={i} {...props}>
        <Placeholder height={130}>
          <rect x="0" y="2" rx="2" ry="2" width="90" height="11" />
          <rect x="0" y="22" rx="2" ry="2" width="270" height="24" />
        </Placeholder>
      </EventContainer>
    );
  }
  return <div>{placeholder}</div>;
};

EventPlaceholder.propTypes = {
  count: PropTypes.number,
};

export default EventPlaceholder;
