import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { toast } from "react-toastify";
import * as clipboard from "clipboard-polyfill";
import { isIOS } from "../helpers";

import { InputFieldLabel } from "../components/FormFields";
import { ScreenReaderElement } from "../components/Accessibility";

import { captureException } from "../helpers";
import * as strings from "../constants/strings";
import { TOAST_AUTOHIDE } from "../constants";

const InputCopyWrapper = styled.div`
  background: hsl(var(--subtle-background-hsl));
  border: 1px solid var(--subtle-border);
  border-radius: var(--border-radius-sm);
  display: flex;
  flex-flow: row nowrap;
`;

const CopyShareLabel = styled(InputFieldLabel)`
  ${ScreenReaderElement}
`;

const CopyShareInput = styled.input`
  appearance: none;
  background: transparent;
  border: 0;
  color: var(--color-text);
  cursor: default;
  display: block;
  font-size: 14px;
  padding: 10px 0 10px 10px;
  resize: none;
  width: 100%;

  // prevent zoom on iOS
  ${isIOS() &&
  css`
    font-size: 16px;
  `}

  &:focus {
    outline: none;
  }
`;

const CopyShareInputWrapper = styled.div`
  flex: 1 0 auto;
  position: relative;

  &::after {
    background: linear-gradient(
      to right,
      hsla(var(--subtle-background-hsl), 0),
      hsla(var(--subtle-background-hsl), 1)
    );
    content: "";
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
    z-index: 1;
  }
`;

const CopyShareButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  color: var(--color-cta);
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 3px;
  padding: 10px;
  text-transform: uppercase;
`;

const CopyShare = (props) => {
  const inputEl = useRef(null);

  const handleFocus = (event) => {
    // Persist React synthetic event
    // See https://reactjs.org/docs/events.html#event-pooling
    const target = event.target;

    // Timeout because iOS refuses to select the text otherwise
    setTimeout(() => {
      target.setSelectionRange(0, 9999);
    }, 0);
  };

  const handleCopy = async () => {
    try {
      await clipboard.writeText(props.value);
      toast.success(strings.COPYSHARE.success, { autoClose: TOAST_AUTOHIDE });
    } catch (error) {
      captureException(error);
      console.log(error);
    }
  };

  return (
    <InputCopyWrapper>
      <CopyShareLabel htmlFor={props.id}>{props.label}</CopyShareLabel>
      <CopyShareInputWrapper>
        <CopyShareInput
          id={props.id}
          onFocus={handleFocus}
          readOnly
          ref={inputEl}
          value={props.value}
        />
      </CopyShareInputWrapper>
      <CopyShareButton onClick={handleCopy}>
        {strings.COPYSHARE.button}
      </CopyShareButton>
    </InputCopyWrapper>
  );
};

export default CopyShare;
