import React from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

const MarkdownStyled = styled(ReactMarkdown)`
  a {
    color: var(--color-cta);
  }

  p,
  li {
    line-height: 1.25;
    margin-top: 0;
  }

  li p {
    margin: 0;
  }

  h1 {
    margin-bottom: 1.5em;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }

  p + h1,
  p + h2,
  ul + h1,
  ul + h2 {
    margin-top: 2em;
  }

  p + h3,
  p + h4,
  p + h5,
  p + h6,
  ul + h3,
  ul + h4,
  ul + h5,
  ul + h6 {
    margin-top: 1.5em;
  }

  blockquote {
    background: var(--quote-background);
    border-radius: var(--border-radius-sm);
    color: var(--text-grey--dark);
    font-size: 14px;
    font-style: italic;
    margin: 0 0 1em 0;
    overflow: hidden;
    padding: 15px 15px 15px 50px;
    position: relative;

    * {
      line-height: 1.4;
    }

    &:before {
      content: open-quote;
      font-size: 90px;
      line-height: 1;
      left: 5px;
      margin: 0;
      opacity: 0.15;
      position: absolute;
      top: 5px;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  iframe {
    border: none;
    max-width: 100%;
  }
`;

const Markdown = (props) => {
  function RouterLink(props) {
    return props.href.match(/^\//) ? (
      <Link to={props.href}>{props.children}</Link>
    ) : (
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    );
  }

  return (
    <MarkdownStyled
      plugins={[gfm]}
      renderers={{ link: RouterLink }}
      escapeHtml={!props.allowHtml}
    >
      {props.children}
    </MarkdownStyled>
  );
};

Markdown.props = {
  children: PropTypes.element.isRequired,
  href: PropTypes.string,
};

export default Markdown;
