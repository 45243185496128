import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Link } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { fetchUser } from "../actions";

import ConditionalWrapper from "./ConditionalWrapper";

const AvatarPhoto = styled.div`
  background: var(--box-bg);
  border-radius: 50%;
  border: 3px solid var(--box-bg);
  box-sizing: content-box;
  overflow: hidden;

  ${(props) =>
    props.size === "xs" &&
    css`
      font-size: 12px;
      height: 16px;
      width: 16px;
    `};

  ${(props) =>
    props.size === "s" &&
    css`
      font-size: 10px;
      height: 20px;
      width: 20px;
    `};

  ${(props) =>
    props.size === "sm" &&
    css`
      font-size: 14px;
      height: 28px;
      width: 28px;
    `};

  ${(props) =>
    props.size === "m" &&
    css`
      font-size: 18px;
      height: 36px;
      width: 36px;
    `};

  ${(props) =>
    props.size === "l" &&
    css`
      font-size: 24px;
      height: 48px;
      width: 48px;
    `};

  ${(props) =>
    props.size === "xl" &&
    css`
      font-size: 42px;
      height: 80px;
      width: 80px;
    `};

  ${(props) =>
    props.action &&
    css`
      align-items: center;
      background: var(--box-bg);
      border: 3px dashed var(--color-border--neutral);
      color: var(--color-border--neutral);
      cursor: pointer;
      display: flex;
      font-size: 22px;
      font-weight: 700;
      justify-content: center;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      transition: border-color var(--transition-time),
        color var(--transition-time);

      &:hover {
        border-color: var(--color-cta);
        color: var(--color-cta);
      }
    `};

  ${(props) =>
    props.action &&
    props.size === "l" &&
    css`
      font-size: 28px;
    `};

  ${(props) =>
    props.action &&
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        border-color: var(--color-border--neutral);
        color: var(--color-border--neutral);
      }
    `};

  ${(props) =>
    props.past &&
    css`
      align-items: center;
      background: var(--color-border--neutral);
      color: var(--box-bg);
      display: flex;
      justify-content: center;
      text-align: center;

      .svg-inline--fa {
        opacity: 0.75;
      }
    `};

  ${(props) =>
    !props.inHouse &&
    css`
      img {
        opacity: 0.25;
      }
    `};

  img {
    width: 100%;
  }
`;

const AvatarTooltip = styled.div`
  background: var(--tooltip-bg);
  border-radius: var(--border-radius-sm);
  color: var(--tooltip-color);
  display: none;
  font-size: 12px;
  left: 50%;
  line-height: 1.1;
  max-width: 100px;
  padding: 4px;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate(-50%, -45px);

  &:after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--tooltip-bg);
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    width: 0;
  }
`;

const AvatarTooltipContainer = styled.div`
  position: relative;

  &:hover ${AvatarTooltip} {
    display: inline-flex;
  }
`;

const Avatar = ({ user, currentUser, userId, fetchUser, ...props }) => {
  useEffect(() => {
    if (userId) {
      fetchUser(userId);
    }
  }, [fetchUser, userId]);

  const renderAvatar = () => {
    if (props.action) {
      return (
        <AvatarPhoto {...props}>
          <FontAwesomeIcon icon={["far", "plus"]} />
        </AvatarPhoto>
      );
    }

    if (props.past) {
      return (
        <AvatarPhoto {...props}>
          <FontAwesomeIcon icon={["fas", "user"]} />
        </AvatarPhoto>
      );
    }

    if (user && user.isFetching) {
      return (
        <AvatarPhoto inHouse={false} {...props} past>
          <FontAwesomeIcon icon={["fas", "user"]} />
        </AvatarPhoto>
      );
    }

    return (
      <AvatarPhoto
        {...props}
        inHouse={user.is_in_house || user.id === currentUser.id}
      >
        <ConditionalWrapper
          condition={props.to}
          wrapper={(children) => <Link to={props.to}>{children}</Link>}
        >
          <img
            src={user.profile_picture_small}
            alt={`${user.first_name} ${user.last_name}`}
            title={`${user.first_name} ${user.last_name}`}
          />
        </ConditionalWrapper>
      </AvatarPhoto>
    );
  };

  if (!user && !props.action) {
    return false;
  }

  if (props.showName) {
    return (
      <AvatarTooltipContainer>
        <AvatarTooltip>
          <span>
            {user.first_name} {user.last_name}
          </span>
        </AvatarTooltip>
        {renderAvatar()}
      </AvatarTooltipContainer>
    );
  }

  return renderAvatar();
};

Avatar.propTypes = {
  action: PropTypes.string,
  add: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fetchUser: PropTypes.func.isRequired,
  inHouse: PropTypes.bool,
  isFetching: PropTypes.bool,
  large: PropTypes.bool,
  past: PropTypes.bool,
  size: PropTypes.string.isRequired,
  to: PropTypes.string,
  user: PropTypes.object,
  userId: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.user,
    user: state.users[ownProps.userId],
  };
};

const mapDispatchToProps = {
  fetchUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
