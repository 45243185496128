import React from "react";
import { Link } from "@reach/router";
import styled, { css } from "styled-components";
import sortBy from "lodash/sortBy";
import orderBy from "lodash/orderBy";
import uniq from "lodash/uniq";
import { parse, isToday, isFuture, isPast } from "date-fns";
import { getTime } from "../helpers";

import * as routes from "../constants/routes";
import * as strings from "../constants/strings";

const FilterContainer = styled.ul`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: stretch;
  line-height: 1;
  list-style: none;
  margin: 0 auto 20px;
  max-width: 360px;
  padding: 0;
  text-transform: uppercase;

  @media (min-width: 1001px) {
    align-self: flex-start;
    flex-flow: column nowrap;
  }
`;

const FilterItem = styled(Link)`
  border: 1px solid var(--color-border--darker);
  border-left-color: transparent;
  border-right-color: transparent;
  color: var(--text-grey);
  cursor: pointer;
  flex: 1 0 auto;
  margin: 0 -1px 0 0;
  padding: 8px 6px 6px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  z-index: 0;

  @media (min-width: 1001px) {
    border: 1px solid transparent !important;
    border-radius: var(--border-radius-sm);
    text-align: left;
  }

  &:first-child {
    border-left-color: var(--color-border--darker);
    border-bottom-left-radius: var(--border-radius-sm);
    border-top-left-radius: var(--border-radius-sm);
  }

  &:last-child {
    border-right-color: var(--color-border--darker);
    border-bottom-right-radius: var(--border-radius-sm);
    border-top-right-radius: var(--border-radius-sm);
  }

  ${(props) =>
    props.active &&
    css`
      border-color: var(--color-cta) !important;
      color: var(--color-cta);
      z-index: 1;

      @media (min-width: 1001px) {
        border: 1px solid var(--color-cta) !important;
      }
    `};
`;

const filterEvents = (filter, events, user, limit) => {
  let result;

  switch (filter) {
    case "mine":
      result = orderBy(
        uniq([
          ...events.filter((x) => x.participants.find((y) => y === user.id)),
          ...events.filter((x) => x.leads.find((y) => y === user.id)),
          ...events.filter((x) => x.created_by === user.id),
        ]),
        ["modified"],
        ["desc"]
      );
      break;
    case "ideas":
      result = orderBy(
        events.filter((item) => item.start_date === null),
        ["modified"],
        ["desc"]
      );
      break;
    case "past":
      result = orderBy(
        events.filter((item) => {
          // set time to last possible millisecond to avoid date's arbitrary time interfering with isPast()
          const date = item.start_date
            ? parse(item.start_date).setHours(23, 59, 59, 999)
            : null;
          const start_time = item.start_time ? getTime(item.start_time) : null;
          const end_time = item.end_time ? getTime(item.end_time) : null;

          return (
            // prettier-ignore
            date && (
              isPast(date) ||
              (isToday(date) && start_time !== null &&
                (
                  (isPast(start_time) && !end_time) ||
                  (end_time && isPast(end_time))
                )
              )
            )
          );
        }),
        ["start_date", "start_time"],
        ["desc"]
      );
      break;
    case "next":
      result = sortBy(
        [
          ...events.filter((x) => x.participants.find((y) => y === user.id)),
          ...events.filter((x) => x.leads.find((y) => y === user.id)),
        ].filter((item) => {
          const date = item.start_date ? parse(item.start_date) : null;
          const start_time = item.start_time ? getTime(item.start_time) : null;
          const end_time = item.end_time ? getTime(item.end_time) : null;

          return (
            (date && isFuture(date)) ||
            (date && isToday(date) && isFuture(start_time)) ||
            (date && isToday(date) && isFuture(end_time))
          );
        }),
        ["start_date", "start_time", "end_time"]
      );
      break;
    default:
      result = sortBy(
        events.filter((item) => {
          const date = item.start_date ? parse(item.start_date) : null;
          const start_time = item.start_time ? getTime(item.start_time) : null;
          const end_time = item.end_time ? getTime(item.end_time) : null;

          return (
            (date && isFuture(date)) ||
            (date && isToday(date) && (!start_time || isFuture(start_time))) ||
            (date && isToday(date) && isFuture(end_time))
          );
        }),
        ["start_date", "start_time", "end_time"]
      );
      break;
  }

  return result.slice(0, limit);
};

const Filters = (props) => {
  return (
    <FilterContainer>
      <FilterItem
        active={props.filter === undefined ? 1 : 0}
        to={routes.EVENT_LIST}
      >
        {strings.FILTER.upcoming}
      </FilterItem>
      <FilterItem
        active={props.filter === "ideas" ? 1 : 0}
        to={`${routes.EVENT_LIST}/ideas`}
      >
        {strings.FILTER.ideas}
      </FilterItem>
      <FilterItem
        active={props.filter === "mine" ? 1 : 0}
        to={`${routes.EVENT_LIST}/mine`}
      >
        {strings.FILTER.mine}
      </FilterItem>
      <FilterItem
        active={props.filter === "past" ? 1 : 0}
        to={`${routes.EVENT_LIST}/past`}
      >
        Past
      </FilterItem>
    </FilterContainer>
  );
};

export default Filters;
export { filterEvents };
