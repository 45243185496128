import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { setMeta, fetchEvents, refreshEvents } from "../actions";
import * as strings from "../constants/strings";
import { CACHE_DURATION } from "../constants";

import ScrollToTop from "../components/ScrollToTop";
import { CarouselWrapper } from "../components/Layout";
import DashboardWidgetEvents from "../Events/DashboardWidgetEvents";
import DashboardWidgetUsers from "../Users/DashboardWidgetUsers";
import ResourceList from "../Resources/ResourceList";
import PageList from "../Pages/PageList";
import FloatingButton from "../components/FloatingButton";

const DashboardGrid = styled.div`
  @media (min-width: 1001px) {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-column-gap: 30px;
  }
`;

class Dashboard extends Component {
  componentDidMount = () => {
    this.props.setMeta({
      navActive: "dashboard",
      parent: "",
      title: strings.TITLES.dashboard,
    });

    this.props.fetchEvents();
    this.refreshList = setInterval(() => {
      this.props.refreshEvents();
    }, CACHE_DURATION);
  };

  componentWillUnmount = () => {
    clearInterval(this.refreshList);
  };

  render() {
    const { events, isFetching, user } = this.props;
    return (
      <ScrollToTop force={true}>
        <CarouselWrapper>
          <DashboardGrid>
            <DashboardWidgetEvents
              ctaLink="/events/mine/"
              ctaText={strings.DASHBOARD.mine_next_cta}
              events={events}
              filter="next"
              isFetching={isFetching}
              limit={1}
              title={strings.DASHBOARD.mine_next}
              user={user}
            />
            <DashboardWidgetUsers />
          </DashboardGrid>
          <DashboardGrid>
            <DashboardWidgetEvents
              ctaLink="/events/"
              ctaText={strings.DASHBOARD.upcoming_cta}
              events={events}
              isFetching={isFetching}
              limit={3}
              title={strings.DASHBOARD.upcoming}
              user={user}
            />
            <DashboardWidgetEvents
              ctaLink="/events/ideas/"
              ctaText={strings.DASHBOARD.ideas_cta}
              events={events}
              filter="ideas"
              isFetching={isFetching}
              limit={3}
              title={strings.DASHBOARD.ideas}
              user={user}
            />
            <div>
              <ResourceList />
              <PageList />
            </div>
          </DashboardGrid>
          {user.is_in_house && <FloatingButton />}
        </CarouselWrapper>
      </ScrollToTop>
    );
  }
}

Dashboard.propTypes = {
  events: PropTypes.array.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  refreshEvents: PropTypes.func.isRequired,
  setMeta: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    events: Object.values(state.events.items),
    isFetching: state.events.isFetching,
    user: state.user,
  };
};

const mapDispatchToProps = {
  setMeta,
  fetchEvents,
  refreshEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
