import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactContentLoader from "react-content-loader";

const PlaceholderTheme = styled.div`
  color: var(--placeholder-color);
`;

const Fallback = ({ width, height }) => (
  <rect x="0" y="0" rx="5" ry="5" width={width} height={height} />
);

const Placeholder = (props) => {
  return (
    <PlaceholderTheme>
      <ReactContentLoader
        preserveAspectRatio={"xMinYMin meet"}
        foregroundColor={"currentColor"}
        foregroundOpacity={"1"}
        backgroundColor={"currentColor"}
        backgroundOpacity={"0.5"}
        height={props.height}
        width={"100%"}
        {...props}
        style={{
          display: "block",
          height: props.height,
          width: "100%",
          ...props.style,
        }}
      >
        {props.children || <Fallback width="100%" height={props.height} />}
      </ReactContentLoader>
    </PlaceholderTheme>
  );
};

Placeholder.propTypes = {
  height: PropTypes.number.isRequired,
  style: PropTypes.object,
};

export default Placeholder;
