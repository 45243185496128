// AUTHENTICATION

export const TOKEN_CHECK = "TOKEN_CHECK";
export const TOKEN_MISSING = "TOKEN_MISSING";
export const INITIALIZING = "INITIALIZING";
export const READY = "READY";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const TOKEN_REQUEST = "TOKEN_REQUEST";
export const TOKEN_RECEIVED = "TOKEN_RECEIVED";
export const TOKEN_ERROR = "TOKEN_ERROR";

export const LOGOUT = "LOGOUT";

// META

export const PAGE_META_SET = "PAGE_META_SET";
// export const PAGE_PARENT_SET = "PAGE_PARENT_SET";

// SINGLE EVENT

export const EVENT_CREATE_REQUEST = "EVENT_CREATE_REQUEST";
export const EVENT_CREATE_SUCCESS = "EVENT_CREATE_SUCCESS";
export const EVENT_CREATE_ERROR = "EVENT_CREATE_ERROR";

export const EVENT_FETCH_REQUEST = "EVENT_FETCH_REQUEST";
export const EVENT_FETCH_SUCCESS = "EVENT_FETCH_SUCCESS";
export const EVENT_FETCH_ERROR = "EVENT_FETCH_ERROR";

export const EVENT_REFRESHED = "EVENT_REFRESHED";

export const EVENT_UPDATE_REQUEST = "EVENT_UPDATE_REQUEST";
export const EVENT_UPDATE_SUCCESS = "EVENT_UPDATE_SUCCESS";
export const EVENT_UPDATE_ERROR = "EVENT_UPDATE_ERROR";

export const EVENT_LOCK_REQUEST = "EVENT_LOCK_REQUEST";
export const EVENT_LOCK_SUCCESS = "EVENT_LOCK_SUCCESS";
export const EVENT_LOCK_DENIED = "EVENT_LOCK_DENIED";
export const EVENT_LOCK_CLEAR_LOCAL = "EVENT_LOCK_CLEAR_LOCAL";

export const EVENT_RELEASE_REQUEST = "EVENT_RELEASE_REQUEST";
export const EVENT_RELEASE_SUCCESS = "EVENT_RELEASE_SUCCESS";
export const EVENT_RELEASE_ERROR = "EVENT_RELEASE_ERROR";

export const EVENT_DELETE_REQUEST = "EVENT_DELETE_REQUEST";
export const EVENT_DELETE_SUCCESS = "EVENT_DELETE_SUCCESS";
export const EVENT_DELETE_ERROR = "EVENT_DELETE_ERROR";

export const EVENT_PARTICIPANT_REQUEST = "EVENT_PARTICIPANT_REQUEST";
export const EVENT_PARTICIPANT_SUCCESS = "EVENT_PARTICIPANT_SUCCESS";
export const EVENT_PARTICIPANT_ERROR = "EVENT_PARTICIPANT_ERROR";

export const EVENT_LEAD_REQUEST = "EVENT_LEAD_REQUEST";
export const EVENT_LEAD_SUCCESS = "EVENT_LEAD_SUCCESS";
export const EVENT_LEAD_ERROR = "EVENT_LEAD_ERROR";

export const EVENT_LEAVE_REQUEST = "EVENT_LEAVE_REQUEST";
export const EVENT_LEAVE_SUCCESS = "EVENT_LEAVE_SUCCESS";
export const EVENT_LEAVE_ERROR = "EVENT_LEAVE_ERROR";

// ALL EVENTS

export const EVENTS_FETCH_REQUEST = "EVENTS_FETCH_REQUEST";
export const EVENTS_FETCH_SUCCESS = "EVENTS_FETCH_SUCCESS";
export const EVENTS_FETCH_ERROR = "EVENTS_FETCH_ERROR";

export const EVENTS_REFRESHED = "EVENTS_REFRESHED";

// PAST EVENTS

export const EVENTS_PAST_FETCH_REQUEST = "EVENTS_PAST_FETCH_REQUEST";
export const EVENTS_PAST_FETCH_SUCCESS = "EVENTS_PAST_FETCH_SUCCESS";
export const EVENTS_PAST_FETCH_ERROR = "EVENTS_PAST_FETCH_ERROR";

// SINGLE USER

export const ME_FETCH_REQUEST = "ME_FETCH_REQUEST";
export const ME_FETCH_SUCCESS = "ME_FETCH_SUCCESS";
export const ME_FETCH_ERROR = "ME_FETCH_ERROR";

export const ME_UPDATE_REQUEST = "ME_UPDATE_REQUEST";
export const ME_UPDATE_SUCCESS = "ME_UPDATE_SUCCESS";
export const ME_UPDATE_ERROR = "ME_UPDATE_ERROR";

export const AVATAR_FETCH_REQUEST = "AVATAR_FETCH_REQUEST";
export const AVATAR_FETCH_SUCCESS = "AVATAR_FETCH_SUCCESS";
export const AVATAR_FETCH_ERROR = "AVATAR_FETCH_ERROR";

export const AVATAR_UPLOAD_START = "AVATAR_UPLOAD_START";
export const AVATAR_UPLOAD_PROGRESS = "AVATAR_UPLOAD_PROGRESS";
export const AVATAR_UPLOAD_SUCCESS = "AVATAR_UPLOAD_SUCCESS";
export const AVATAR_UPLOAD_ERROR = "AVATAR_UPLOAD_ERROR";
export const AVATAR_UPLOAD_RESET = "AVATAR_UPLOAD_RESET";

export const USER_FETCH_REQUEST = "USER_FETCH_REQUEST";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_ERROR = "USER_FETCH_ERROR";

// ALL USERS

export const USERS_FETCH_REQUEST = "USERS_FETCH_REQUEST";
export const USERS_FETCH_SUCCESS = "USERS_FETCH_SUCCESS";
export const USERS_FETCH_ERROR = "USERS_FETCH_ERROR";

// EVENT TYPES

export const EVENTTYPES_FETCH_REQUEST = "EVENTTYPES_FETCH_REQUEST";
export const EVENTTYPES_FETCH_SUCCESS = "EVENTTYPES_FETCH_SUCCESS";
export const EVENTTYPES_FETCH_ERROR = "EVENTTYPES_FETCH_ERROR";

// RESOURCES

export const RESOURCES_FETCH_REQUEST = "RESOURCES_FETCH_REQUEST";
export const RESOURCES_FETCH_SUCCESS = "RESOURCES_FETCH_SUCCESS";
export const RESOURCES_FETCH_ERROR = "RESOURCES_FETCH_ERROR";

// STATIC PAGES

export const PAGES_FETCH_REQUEST = "PAGES_FETCH_REQUEST";
export const PAGES_FETCH_SUCCESS = "PAGES_FETCH_SUCCESS";
export const PAGES_FETCH_ERROR = "PAGES_FETCH_ERROR";

export const PAGE_FETCH_REQUEST = "PAGE_FETCH_REQUEST";
export const PAGE_FETCH_SUCCESS = "PAGE_FETCH_SUCCESS";
export const PAGE_FETCH_ERROR = "PAGE_FETCH_ERROR";
