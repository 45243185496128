import { toast } from "react-toastify";

import * as ACTION from "./types";
import django from "../apis/django";
import { captureException } from "../helpers";

export const fetchResources = () => async (dispatch, getState) => {
  // prevent a refetch once resources have been loaded
  if (getState().resources.items.length > 0) {
    return null;
  }

  dispatch({ type: ACTION.RESOURCES_FETCH_REQUEST });

  try {
    const response = await django.get("/resources/");
    dispatch({
      type: ACTION.RESOURCES_FETCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ACTION.RESOURCES_FETCH_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};
