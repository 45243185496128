import mapKeys from "lodash/mapKeys";

import { EVENTTYPES_FETCH_SUCCESS } from "../actions/types";

const eventTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENTTYPES_FETCH_SUCCESS:
      return { ...state, ...mapKeys(action.payload, "id") };
    default:
      return state;
  }
};

export default eventTypesReducer;
