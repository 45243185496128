import * as ACTION from "./types";

export const setMeta = (meta) => (dispatch) => {
  // Check if `titleBrowser` defined, otherwise default to `title`
  const pageTitle = meta.titleBrowser ? meta.titleBrowser : meta.title;

  // Add prefix to document titles
  const titleBrowser = `${process.env.REACT_APP_COMMUNITY_NAME} - ${pageTitle}`;

  // Push to document <title>
  document.title = titleBrowser;

  dispatch({
    type: ACTION.PAGE_META_SET,
    payload: {
      ...meta,
      titleBrowser,
    },
  });
};
