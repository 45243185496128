import React from "react";
import styled from "styled-components";
import Component from "@reach/component-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  LightboxOverlay,
  LightboxContent,
  LightboxCloseContainer,
  LightboxClose,
} from "../components/Lightbox";
import { ContentBox } from "../components/Layout";
import { DialogHeading, H2 } from "../components/Headings";
import { FormRow } from "../components/FormFields";
import Button from "../components/Button";
import CopyShare from "../components/CopyShare";
import Splitter from "../components/Splitter";

import * as strings from "../constants/strings";

const CalendarContainer = styled(ContentBox)`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 15px 20px 15px;
  text-align: center;

  .svg-inline--fa {
    color: var(--text-grey);
    font-size: 25px;
  }
`;

const CalendarIntegration = (props) => {
  const { user } = props;
  return (
    <Component initialState={{ showDialog: false }}>
      {({ state, setState }) => (
        <>
          <CalendarContainer>
            <FontAwesomeIcon icon={["fal", "calendar"]} />
            <p>{strings.CALENDAR.title}</p>
            <Button
              inverted
              onClick={(e) => {
                e.preventDefault();
                setState({ showDialog: true });
              }}
            >
              {strings.CALENDAR.connect}
            </Button>
          </CalendarContainer>
          <LightboxOverlay
            isOpen={state.showDialog}
            onDismiss={() => setState({ showDialog: false })}
          >
            <LightboxContent
              aria-label={strings.CALENDAR.overlay_arialabel}
              size="md"
            >
              <LightboxCloseContainer sticky>
                <LightboxClose
                  inverted
                  onClick={() => setState({ showDialog: false })}
                  round
                  small
                  title={strings.LIGHTBOX.close}
                >
                  <FontAwesomeIcon icon={["fas", "times"]} />
                </LightboxClose>
              </LightboxCloseContainer>
              <DialogHeading>{strings.CALENDAR.overlay_header}</DialogHeading>
              <p style={{ marginBottom: 0 }}>{strings.CALENDAR.explainer}</p>
              <Splitter />
              <FormRow>
                <H2>{strings.CALENDAR.header_all}</H2>
                <CopyShare
                  id="calendar_all"
                  label={strings.CALENDAR.header_all}
                  value={`${window.location.protocol}//${window.location.hostname}/calendar/all/?user_id=${user.id}&access_key=${user.access_key}`}
                />
              </FormRow>
              <FormRow>
                <H2>{strings.CALENDAR.header_mine}</H2>
                <CopyShare
                  id="calendar_mine"
                  label={strings.CALENDAR.header_mine}
                  value={`${window.location.protocol}//${window.location.hostname}/calendar/mine/?user_id=${user.id}&access_key=${user.access_key}`}
                />
              </FormRow>
            </LightboxContent>
          </LightboxOverlay>
        </>
      )}
    </Component>
  );
};

export default CalendarIntegration;
