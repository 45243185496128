import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form, Formik, FieldArray, ErrorMessage } from "formik";

import { ContentBox } from "../components/Layout";
import Avatar from "../components/Avatar";
import AvatarUpload from "./AvatarUpload";
import {
  FormRow,
  FormRowCompact,
  FormActions,
  InputFieldLabel,
  InputField,
  InputAutosize,
  InputInfo,
} from "../components/FormFields";
import Notice from "../components/Notice";
import { H2 } from "../components/Headings";
import Markdown from "../components/Markdown";
import Button from "../components/Button";

import * as strings from "../constants/strings";

const ProfileUserContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const ProfileAvatarButton = styled.div`
  left: 80px;
  position: absolute;
  top: 100px;
`;

const ProfileAvatar = styled.div`
  align-items: center;
  display: flex;
  margin-right: 30px;
`;

const ProfileName = styled.div``;

const ProfileForm = ({ handleSubmit, user }) => {
  const renderForm = () => (
    <Formik
      initialValues={{
        first_name: user.first_name,
        last_name: user.last_name,
        questions: user.questions,
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting }) => (
        <>
          <Form>
            <FormRow>
              <ProfileUserContainer>
                <ProfileAvatar>
                  <Avatar userId={user.id} size="xl" />
                </ProfileAvatar>
                <ProfileName>
                  <FormRowCompact>
                    <InputFieldLabel>
                      {strings.PROFILE.full_name} <small>required</small>
                    </InputFieldLabel>
                    <InputField
                      type="text"
                      name="first_name"
                      placeholder={strings.PROFILE.first_name}
                      compact={1}
                      required
                    />
                    <ErrorMessage name="first_name" component="div" />
                  </FormRowCompact>
                  <FormRowCompact>
                    <InputField
                      type="text"
                      name="last_name"
                      placeholder={strings.PROFILE.last_name}
                      compact={1}
                      required
                    />
                    <ErrorMessage name="last_name" component="div" />
                  </FormRowCompact>
                </ProfileName>
              </ProfileUserContainer>
            </FormRow>

            <H2>{strings.PROFILE.questions_header}</H2>
            <Notice type="error">
              <Markdown>{strings.PROFILE.questions_notice}</Markdown>
            </Notice>

            <FieldArray name="questions">
              {() =>
                values.questions.map((question, index) => (
                  <FormRow key={question.id}>
                    <InputFieldLabel htmlFor={`question-${question.id}`}>
                      {question.title}
                    </InputFieldLabel>
                    {question.description && (
                      <InputInfo>
                        <Markdown>{question.description}</Markdown>
                      </InputInfo>
                    )}
                    <InputField name={`questions[${index}].answer`}>
                      {({ field }) => (
                        <InputAutosize
                          {...field}
                          id={`question-${question.id}`}
                          maxRows={10}
                          minRows={4}
                          placeholder={question.question}
                        />
                      )}
                    </InputField>
                  </FormRow>
                ))
              }
            </FieldArray>
            <FormActions sticky>
              <span>
                <Button type="submit" disabled={isSubmitting}>
                  {strings.PROFILE.update}
                </Button>
              </span>
            </FormActions>
          </Form>
        </>
      )}
    </Formik>
  );

  return (
    <>
      <ContentBox>
        <ProfileAvatarButton>
          <AvatarUpload />
        </ProfileAvatarButton>
        {renderForm()}
      </ContentBox>
    </>
  );
};

ProfileForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ProfileForm;
