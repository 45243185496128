import { checkCacheValid } from "redux-cache";
import { navigate } from "@reach/router";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";

import * as ACTION from "./types";
import * as routes from "../constants/routes";
import * as strings from "../constants/strings";
import { CACHE_EVENTS, CACHE_EVENTS_PAST, TOAST_AUTOHIDE } from "../constants";
import { logout } from "../actions/auth";

import django from "../apis/django";
import {
  fixDateInEvent,
  fixTimesInEvent,
  captureException,
  isExpired,
} from "../helpers";

// EVENT TYPES

export const fetchEventTypes = () => async (dispatch, getState) => {
  // prevent a refetch once eventTypes have been loaded
  if (!isEmpty(getState().eventTypes)) {
    return null;
  }

  dispatch({ type: ACTION.EVENTTYPES_FETCH_REQUEST });

  try {
    const response = await django.get("/event-types/");
    dispatch({
      type: ACTION.EVENTTYPES_FETCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ACTION.EVENTTYPES_FETCH_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

// SINGLE EVENT

export const createEvent = (formValues) => async (dispatch) => {
  dispatch({ type: ACTION.EVENT_CREATE_REQUEST });

  let fixedValues = fixDateInEvent(formValues);
  fixedValues = fixTimesInEvent(fixedValues, ["start_time", "end_time"]);

  try {
    const response = await django.post("/events/", fixedValues);
    dispatch({
      type: ACTION.EVENT_CREATE_SUCCESS,
      payload: response.data,
    });
    navigate(`${routes.EVENT_DIR}/${response.data.id}/`);
  } catch (error) {
    dispatch({
      type: ACTION.EVENT_CREATE_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

export const fetchEvent = (id) => async (dispatch) => {
  dispatch({ type: ACTION.EVENT_FETCH_REQUEST });

  // Make sure we have event types first
  await dispatch(fetchEventTypes());

  try {
    const token = localStorage.getItem("token");
    if (token && !isExpired(token)) {
      const response = await django.get(`/events/${id}/`);
      dispatch({
        type: ACTION.EVENT_FETCH_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch(logout(null, strings.LOGOUT.TOKEN_EXPIRED));
    }
  } catch (error) {
    dispatch({
      type: ACTION.EVENT_FETCH_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

export const refreshEvent = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    if (token && !isExpired(token)) {
      const response = await django.get(`/events/${id}/`);
      dispatch({
        type: ACTION.EVENT_REFRESHED,
        payload: response.data,
      });
    } else {
      dispatch(logout(null, strings.LOGOUT.TOKEN_EXPIRED));
    }
  } catch (error) {
    captureException(error);
  }
};

export const lockEvent = (id) => async (dispatch) => {
  dispatch({ type: ACTION.EVENT_LOCK_REQUEST });

  try {
    const response = await django.post(`/events/${id}/acquire_lock/`);

    dispatch({
      type:
        response.data.status === "success"
          ? ACTION.EVENT_LOCK_SUCCESS
          : ACTION.EVENT_LOCK_DENIED,
      payload: response.data,
    });
  } catch (error) {
    captureException(error);
  }
};

export const releaseEvent = (id) => async (dispatch) => {
  dispatch({ type: ACTION.EVENT_RELEASE_REQUEST });

  try {
    await django.post(`/events/${id}/release_lock/`);

    dispatch({
      type: ACTION.EVENT_RELEASE_SUCCESS,
    });
  } catch (error) {
    captureException(error);
  }
};

export const clearLocalLock = () => (dispatch) => {
  dispatch({ type: ACTION.EVENT_LOCK_CLEAR_LOCAL });
};

export const editEvent = (id, formValues) => async (dispatch) => {
  dispatch({ type: ACTION.EVENT_UPDATE_REQUEST });

  try {
    let fixedValues = fixDateInEvent(formValues);
    fixedValues = fixTimesInEvent(fixedValues, ["start_time", "end_time"]);

    const response = await django.patch(`/events/${id}/`, fixedValues);
    dispatch({
      type: ACTION.EVENT_UPDATE_SUCCESS,
      payload: response.data,
    });
    await dispatch(releaseEvent(id));
    navigate(`${routes.EVENT_DIR}/${response.data.id}/`);
  } catch (error) {
    dispatch({
      type: ACTION.EVENT_UPDATE_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  dispatch({ type: ACTION.EVENT_DELETE_REQUEST });

  try {
    await django.delete(`/events/${id}/`);
    navigate(`${routes.EVENT_LIST}`);
    dispatch({
      type: ACTION.EVENT_DELETE_SUCCESS,
      payload: id,
    });
    toast.success("Event was deleted successfully.", {
      autoClose: TOAST_AUTOHIDE,
    });
  } catch (error) {
    dispatch({
      type: ACTION.EVENT_DELETE_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

// SINGLE EVENT MEMBERSHIP

export const joinEvent = (eventId, userId) => async (dispatch) => {
  dispatch({ type: ACTION.EVENT_PARTICIPANT_REQUEST });

  try {
    await django.post(`/events/${eventId}/add_participant/`, {
      id: userId,
    });
    const response = await django.get(`/events/${eventId}/`);

    dispatch({
      type: ACTION.EVENT_PARTICIPANT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ACTION.EVENT_PARTICIPANT_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

export const leadEvent = (eventId, userId) => async (dispatch) => {
  dispatch({ type: ACTION.EVENT_LEAD_REQUEST });

  try {
    await django.post(`/events/${eventId}/add_lead/`, {
      id: userId,
    });
    const response = await django.get(`/events/${eventId}/`);
    dispatch({
      type: ACTION.EVENT_LEAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ACTION.EVENT_LEAD_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

export const leaveEvent = (eventId, userId) => async (dispatch) => {
  dispatch({ type: ACTION.EVENT_LEAVE_REQUEST });

  try {
    await django.post(`/events/${eventId}/remove_participant/`, {
      id: userId,
    });
    const response = await django.get(`/events/${eventId}/`);
    dispatch({
      type: ACTION.EVENT_LEAVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ACTION.EVENT_LEAVE_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

// ALL EVENTS

export const fetchEvents = (options = null) => async (dispatch, getState) => {
  // cache results to prevent unnecessary fetches
  const isCacheValid = checkCacheValid(getState, "events", {
    cacheKey: CACHE_EVENTS,
  });
  if (isCacheValid) {
    return null;
  }

  dispatch({ type: ACTION.EVENTS_FETCH_REQUEST });

  // Make sure we have event types first
  await dispatch(fetchEventTypes());

  try {
    const token = localStorage.getItem("token");
    if (token && !isExpired(token)) {
      const response = await django.get("/events/");
      dispatch({
        type: ACTION.EVENTS_FETCH_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch(logout(null, strings.LOGOUT.TOKEN_EXPIRED));
    }
  } catch (error) {
    dispatch({
      type: ACTION.EVENTS_FETCH_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};

export const refreshEvents = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    if (token && !isExpired(token)) {
      const response = await django.get("/events/");
      dispatch({
        type: ACTION.EVENTS_REFRESHED,
        payload: response.data,
      });
    } else {
      dispatch(logout(null, strings.LOGOUT.TOKEN_EXPIRED));
    }
  } catch (error) {
    captureException(error);
  }
};

// PAST EVENTS

export const fetchEventsPast = (options = null) => async (
  dispatch,
  getState
) => {
  // cache results to prevent unnecessary fetches
  const isCacheValid = checkCacheValid(getState, "events", {
    cacheKey: CACHE_EVENTS_PAST,
  });
  if (isCacheValid) {
    return null;
  }

  dispatch({ type: ACTION.EVENTS_PAST_FETCH_REQUEST });

  // Make sure we have event types first
  await dispatch(fetchEventTypes());

  try {
    const token = localStorage.getItem("token");
    if (token && !isExpired(token)) {
      const response = await django.get("/events/past/");
      dispatch({
        type: ACTION.EVENTS_PAST_FETCH_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch(logout(null, strings.LOGOUT.TOKEN_EXPIRED));
    }
  } catch (error) {
    dispatch({
      type: ACTION.EVENTS_PAST_FETCH_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};
