import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

import * as strings from "../constants/strings";
import * as routes from "../constants/routes";

import { setMeta, fetchPages } from "../actions";

import ScrollToTop from "../components/ScrollToTop";
import { ContentContainer } from "../components/Layout";
import PagePlaceholder from "./PagePlaceholder";
import Markdown from "../components/Markdown";
import { H1 } from "../components/Headings";
import Error from "../views/Error";

export class StaticPage extends Component {
  componentDidMount = () => {
    const { page } = this.props;

    this.props.fetchPages();

    this.props.setMeta({
      navActive: "dashboard",
      parent: routes.DASHBOARD,
      title: !isEmpty(page) ? page.name : "",
    });
  };

  componentDidUpdate = (prevProps) => {
    const { page } = this.props;

    if (!isEmpty(page) && !isEqual(page, prevProps.page)) {
      this.props.setMeta({
        title: page.name,
      });
    }
  };

  renderContent() {
    const { page, isFetching } = this.props;

    if (isFetching) {
      return <PagePlaceholder />;
    }

    if (!page || page.type === "external") {
      return <Error content={strings.ERROR_PAGE_404} />;
    }

    return (
      <>
        <H1>{page.name}</H1>
        <Markdown allowHtml>{page.content}</Markdown>
      </>
    );
  }

  render() {
    return (
      <ScrollToTop force={true}>
        <ContentContainer>{this.renderContent()}</ContentContainer>
      </ScrollToTop>
    );
  }
}

StaticPage.propTypes = {
  page: PropTypes.object,
  setMeta: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    page: state.pages.items[ownProps.slug],
    isFetching: state.pages.isFetching,
  };
};

const mapDispatchToProps = {
  setMeta,
  fetchPages,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaticPage);
