import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as Sentry from "@sentry/browser";
import styled from "styled-components";
import { GoogleLogin } from "react-google-login";
import { toast } from "react-toastify";
import Component from "@reach/component-component";
import Markdown from "../components/Markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  LightboxOverlay,
  LightboxContent,
  LightboxButtonRow,
  LightboxCloseContainer,
  LightboxClose,
} from "../components/Lightbox";
import Button, { TextButton } from "../components/Button";
import { DialogHeading } from "../components/Headings";

import Landing, { LandingText } from "../components/Landing";
import { H1 } from "../components/Headings";

import { login, setMeta } from "../actions";

import * as strings from "../constants/strings";
import * as brandData from "../branding/brandData";

const LoginContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
`;

const LoginButton = styled.button`
  background: var(--color-cta);
  border: none;
  border-radius: var(--border-radius-button);
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  /* margin-bottom: 5px; */
  padding: 13px 10px;
  transition: opacity var(--transition-time);
  width: 230px;

  &:hover {
    opacity: 0.85;
  }
`;

const LoginPrivacy = styled(TextButton)`
  color: var(--text-grey);
  font-size: 12px;
  margin-top: 15px;
  opacity: 0.8;
  transition: color var(--transition-time);

  &:hover {
    color: var(--color-text);
  }
`;

class Login extends React.Component {
  componentDidMount = () => {
    this.props.setMeta({
      title: "Login",
    });
  };

  onRequest = () => {
    // hide the toast that informs about a logout
    toast.dismiss("logout");
  };

  onSuccess = (response) => {
    this.props.login(response);
  };

  onFailure = (response) => {
    toast.error(response.error);
    Sentry.captureException(response);
  };

  render() {
    return (
      <Component initialState={{ showDialog: false }}>
        {({ state, setState }) => (
          <Landing>
            <LandingText>
              <FontAwesomeIcon icon={["fas", "umbrella-beach"]} />
              <H1>{brandData.LOGIN.header}</H1>
              <p>{brandData.LOGIN.intro}</p>
            </LandingText>
            <LoginContainer>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_API_CLIENT_ID}
                buttonText="Login"
                onRequest={this.onRequest}
                onSuccess={this.onSuccess}
                onFailure={this.onFailure}
                render={(renderProps) => (
                  <LoginButton onClick={renderProps.onClick}>
                    {strings.LOGIN.button_login}
                  </LoginButton>
                )}
              />
              <LoginPrivacy
                small
                onClick={(e) => {
                  e.preventDefault();
                  setState({ showDialog: true });
                }}
              >
                {strings.LOGIN.explainer_open}
              </LoginPrivacy>
            </LoginContainer>
            <LightboxOverlay
              isOpen={state.showDialog}
              onDismiss={() => setState({ showDialog: false })}
            >
              <LightboxContent
                aria-label={strings.LOGIN.explainer_open}
                size="md"
              >
                <LightboxCloseContainer sticky>
                  <LightboxClose
                    onClick={() => setState({ showDialog: false })}
                    round
                    small
                    inverted
                    title={strings.LIGHTBOX.close}
                  >
                    <FontAwesomeIcon icon={["fas", "times"]} />
                  </LightboxClose>
                </LightboxCloseContainer>
                <DialogHeading>{brandData.LOGIN.privacy_header}</DialogHeading>

                <Markdown>{brandData.LOGIN.privacy_text}</Markdown>
                <Markdown>{brandData.staticPages[1].content}</Markdown>

                <LightboxButtonRow>
                  <Button
                    onClick={() => setState({ showDialog: false })}
                    type="button"
                  >
                    <span>{strings.LOGIN.explainer_ok}</span>
                  </Button>
                </LightboxButtonRow>
              </LightboxContent>
            </LightboxOverlay>
          </Landing>
        )}
      </Component>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  login,
  setMeta,
};

export default connect(null, mapDispatchToProps)(Login);
