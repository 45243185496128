import {
  format,
  parse,
  distanceInWordsToNow,
  isPast,
  isToday,
  isTomorrow,
} from "date-fns";
import jwt_decode from "jwt-decode";
import * as Sentry from "@sentry/browser";

import * as strings from "../constants/strings";

// Check if we're on a mobile device
// Currently only using this to display native datepickers
export const isMobileOS = () => {
  return /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);
};

export const isIOS = () => {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
};

// Check if we're running on localhost
export const isLocalhost = () => {
  return /localhost|127\.0\.0\.1|::1|\.local|^$/i.test(
    window.location.hostname
  );
};

// If user is logged in, register them with Sentry
export const registerUserInSentry = (token) => {
  if (!token) {
    return;
  }
  const tokenDecoded = jwt_decode(token);
  Sentry.configureScope((scope) => {
    scope.setUser({ email: tokenDecoded.email });
  });
};

// Handle errors by capturing them with Sentry
// Prevent Sentry if we’re running on localhost
export const captureException = (error) => {
  if (!isLocalhost()) {
    Sentry.captureException(error);
  }
};

// ReactDatePicker requires a proper Date() object, so create it
// out of the value provided by the server
// ! Don't use this for date comparisons as it will fail on mobile
export const properDate = (date) => {
  switch (date) {
    case null:
      return isMobileOS() ? "" : null;
    default:
      return isMobileOS() ? date : new Date(date);
  }
};

// Server requires a date format other than what Date() outputs,
// so convert it before submitting
export const fixDateInEvent = (event) => {
  const fixedDate = event.start_date
    ? format(new Date(event.start_date), "YYYY-MM-DD")
    : null;
  return { ...event, start_date: fixedDate };
};

export const getTime = (time) => {
  if (time) {
    const fixedTime = new Date(),
      timeString = time,
      parts = timeString.match(/(\d+):(\d+):(\d+)/),
      hours = parseInt(parts[1]),
      minutes = parseInt(parts[2]);
    fixedTime.setHours(hours);
    fixedTime.setMinutes(minutes);
    return fixedTime;
  }
  return null;
};

// ReactDatePicker only accepts Date() objects, so we have to
// convert the string from the response to a Date() object. Note
// that the date is totally arbitrary and is removed on submitting
// to the server. "string" mode is only needed when we need to preserve
// the string data type.
export const properTime = (time, mode) => {
  if (time) {
    const fixedTime = new Date(),
      timeString = time,
      parts = timeString.match(/(\d+):(\d+):(\d+)/),
      hours = parseInt(parts[1]),
      minutes = parseInt(parts[2]);
    fixedTime.setHours(hours);
    fixedTime.setMinutes(minutes);
    return isMobileOS() && mode !== "string" ? time : fixedTime;
  }
  return isMobileOS() && mode !== "string" ? "" : null;
};

// ReactDatePicker only accepts Date() objects, and Date() only
// accepts full dates (even though we only want to select a time).
// So we have arbitrary dates on start and end times of events.
// The server only accepts a certain format, and we don't want to
// submit the arbitrary date with the time, so we need to remove
// it before submitting. That's what happens here. ¯\_(ツ)_/¯
export const fixTimesInEvent = (event, fields) => {
  const fixedEvent = { ...event };
  fields.forEach((item) => {
    const fixedTime = event[item]
      ? isMobileOS()
        ? event[item]
        : format(new Date(event[item]), "HH:mm")
      : null;
    fixedEvent[item] = fixedTime;
  });
  return fixedEvent;
};

export const getRelativeTime = (day, time) => {
  const date = new Date(parse(`${day}T${time}`));

  if (isPast(date)) {
    return ` ${strings.TIME.happening_now}`;
  }

  if (isToday(date)) {
    return ` ${strings.TIME.starts} ${distanceInWordsToNow(date)}`;
  }

  if (isTomorrow(date)) {
    return ` ${strings.TIME.is} ${strings.TIME.tomorrow}`;
  }

  // if (isThisWeek(date, { weekStartsOn: 1 })) {
  //   return ` ${strings.TIME.is_on} ${distanceInWordsToNow(date)}`;
  // }

  return ` ${strings.TIME.is_in} ${distanceInWordsToNow(date)}`;
};

// Check if a token has expired
export const isExpired = (token) => {
  const tokenDecoded = jwt_decode(token);
  const currentTime = Date.now().valueOf() / 1000; // use .valueOf() for plain UTC time to prevent timezone issues
  if (tokenDecoded.exp > currentTime) {
    return false;
  }
  return true;
};

// Convert special characters to their closest ASCII equivalents for sorting
export const normalizeCharsForSort = (text) => {
  return text.normalize("NFKD").toLowerCase();
};

// Generate a random number
export const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

// Capitalize first letter
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const scrollToRef = (ref, offset = 0) => {
  const elementRect = ref.current.getBoundingClientRect().top;
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect - offset;
  window.scrollTo({
    top: elementPosition,
    behavior: "smooth",
  });
};
