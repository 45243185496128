import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { format } from "date-fns";

import * as routes from "../constants/routes";
import * as strings from "../constants/strings";
import * as brandData from "../branding/brandData";
import { TOAST_AUTOHIDE } from "../constants";

import ScrollToTop from "../components/ScrollToTop";
import { ContentContainer } from "../components/Layout";
import ProfileForm from "./ProfileForm";
// import ProfilePlaceholder from "./ProfilePlaceholder";
import Button from "../components/Button";
import Splitter from "../components/Splitter";
import Markdown from "../components/Markdown";
import ActionsSecondary from "../components/ActionsSecondary";
import { H2 } from "../components/Headings";
import CalendarIntegration from "../components/CalendarIntegration";

import { setMeta, fetchMe, editMe, logout } from "../actions";

const Profile = ({ editMe, fetchMe, logout, setMeta, user }) => {
  useEffect(() => {
    // make sure user info is up-to-date to avoid unintended overwriting
    fetchMe();
  }, [fetchMe]);

  useEffect(() => {
    setMeta({
      navActive: "profile",
      parent: routes.DASHBOARD,
      title: strings.TITLES.profile_mine,
      titleBrowser: `${user.first_name} ${user.last_name}`,
    });
  }, [setMeta, user.first_name, user.last_name]);

  const handleSubmit = (formValues) => {
    editMe(formValues);
  };

  const renderContent = () => {
    // const { isFetching } = user;
    const dateJoined = format(new Date(user.date_joined), "MMMM YYYY");

    // if (isFetching) {
    //   return <ProfilePlaceholder />;
    // }

    return (
      <>
        <ProfileForm user={user} handleSubmit={handleSubmit} />
        <Splitter kind="silent" />
        <H2>{strings.INTEGRATIONS.title}</H2>
        <CalendarIntegration user={user} />
        <Splitter />
        <p>
          {strings.PROFILE.google_account} <strong>{user.email}</strong>.
        </p>
        <Markdown>{brandData.PROFILE.privacy}</Markdown>
        <p>
          <small>
            {strings.PROFILE.joined} {dateJoined}
          </small>
        </p>
        <Splitter kind="silent" />
        <ActionsSecondary>
          <Button
            onClick={() =>
              logout(null, strings.LOGOUT.SUCCESS, true, {
                autoClose: TOAST_AUTOHIDE,
              })
            }
            inverted
            warning
          >
            {strings.PROFILE.logout}
          </Button>
        </ActionsSecondary>
      </>
    );
  };

  return (
    <ScrollToTop force={true}>
      <ContentContainer>{renderContent()}</ContentContainer>
    </ScrollToTop>
  );
};

Profile.propTypes = {
  editMe: PropTypes.func.isRequired,
  fetchMe: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  setMeta: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  setMeta,
  fetchMe,
  editMe,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
