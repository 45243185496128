import { navigate } from "@reach/router";
import { toast } from "react-toastify";

import { isExpired, registerUserInSentry, captureException } from "../helpers";
import django from "../apis/django";
import { initialize } from "./init";

import * as ACTION from "./types";
import * as strings from "../constants/strings";

// AUTHENTICATION

export const login = (googleResponse) => async (dispatch) => {
  dispatch({ type: ACTION.LOGIN_REQUEST });

  try {
    const response = await django.post("/users/get_token/", {
      access_token: googleResponse.accessToken,
    });

    const { token } = response.data;

    // register user in Sentry error tracker
    registerUserInSentry(token);

    localStorage.setItem("token", token);

    // update the preconfigured axios instance with the token
    // eslint-disable-next-line require-atomic-updates
    django.defaults.headers["Authorization"] = `Bearer ${token}`;

    dispatch({
      type: ACTION.LOGIN_SUCCESS,
      payload: response.data,
    });

    dispatch(initialize());
  } catch (error) {
    dispatch({
      type: ACTION.LOGIN_ERROR,
      payload: { response: error.message },
    });
    toast.error(error.message);
    captureException(error);
  }
};

export const confirmAuth = () => async (dispatch) => {
  dispatch({ type: ACTION.TOKEN_CHECK });

  try {
    const token = localStorage.getItem("token");

    if (token) {
      // register user in Sentry error tracker
      registerUserInSentry(token);

      if (!isExpired(token)) {
        // update the preconfigured axios instance with the token
        django.defaults.headers["Authorization"] = `Bearer ${token}`;

        dispatch({
          type: ACTION.LOGIN_SUCCESS,
          payload: { token: token },
        });
        dispatch(initialize());
      } else {
        dispatch(logout(null, strings.LOGOUT.TOKEN_EXPIRED));
      }
    } else {
      dispatch({ type: ACTION.TOKEN_MISSING });
    }
  } catch (error) {
    captureException(error);
  }
};

export const logout = (e, notification, redirect, params = null) => (
  dispatch
) => {
  localStorage.removeItem("token");

  // remove the token from the axios instance
  django.defaults.headers["Authorization"] = null;

  dispatch({
    type: ACTION.LOGOUT,
  });

  if (redirect) {
    navigate("/");
  }

  if (notification) {
    toast.info(notification, { ...params, toastId: "logout" });
  }
};
