import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import sortBy from "lodash/sortBy";

import { normalizeCharsForSort } from "../helpers";

import * as strings from "../constants/strings";
import * as routes from "../constants/routes";

import moreIcon from "../icons/more.svg";

import {
  WidgetTitle,
  WidgetTitleLink,
  WidgetTitleIcon,
} from "../components/DashboardWidgetTitle";
import Avatar from "../components/Avatar";

const UserListContainer = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  margin: 0 -10px 40px;
  overflow-y: auto;
  padding: 0 10px;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 600px) {
    flex-flow: row wrap;
    margin: 0 0 40px;
    overflow: visible;
    padding: 0;
  }
`;

const UserListItem = styled.li`
  display: flex;
  margin: 0 5px 5px 0;
  padding: 0;
`;

export class DashboardWidgetUsers extends Component {
  render() {
    const { users } = this.props;

    return (
      <div>
        <WidgetTitle>
          <WidgetTitleLink
            to={routes.USERS}
            title={strings.DASHBOARD.in_house_cta}
          >
            {strings.DASHBOARD.in_house}{" "}
            <WidgetTitleIcon>
              <img src={moreIcon} alt={strings.DASHBOARD.in_house_cta} />
            </WidgetTitleIcon>
          </WidgetTitleLink>
        </WidgetTitle>
        <UserListContainer>
          {users.map(
            (user) =>
              user.is_in_house && (
                <UserListItem key={user.id}>
                  <Avatar
                    userId={user.id}
                    size="l"
                    showName
                    to={`${routes.USERS}/${user.id}`}
                  />
                </UserListItem>
              )
          )}
        </UserListContainer>
      </div>
    );
  }
}

DashboardWidgetUsers.propTypes = {
  users: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  users: sortBy(
    Object.values(state.users),
    [
      (user) =>
        user && user.first_name && normalizeCharsForSort(user.first_name),
      (user) => user && user.last_name && normalizeCharsForSort(user.last_name),
    ],
    [("first_name", "last_name")]
  ),
});

export default connect(mapStateToProps)(DashboardWidgetUsers);
