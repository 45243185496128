import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as strings from "../constants/strings";
import * as routes from "../constants/routes";

import { setMeta, createEvent } from "../actions";

import { ContentContainer } from "../components/Layout";
import { EventContainer } from "./EventItem";
import EventForm from "./EventForm";

const EventCreate = ({ createEvent, setMeta, user }) => {
  useEffect(() => {
    setMeta({
      navActive: "events",
      parent: routes.EVENT_LIST,
      title: strings.TITLES.event_new,
    });
  });

  return (
    <ContentContainer>
      <EventContainer>
        <EventForm createEvent={createEvent} lock={!user.is_in_house} />
      </EventContainer>
    </ContentContainer>
  );
};

EventCreate.propTypes = {
  createEvent: PropTypes.func.isRequired,
  setMeta: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  setMeta,
  createEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventCreate);
