import React from "react";
import styled, { css } from "styled-components";
import * as Sentry from "@sentry/browser";

import Markdown from "../components/Markdown";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as strings from "../constants/strings";

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - var(--app-top-padding) - var(--app-bottom-padding));
  justify-content: center;
  padding: 10px;
  text-align: center;

  ${(props) =>
    props.fullscreen &&
    css`
      height: 100vh;
    `};
`;

const ErrorHeader = styled.h1`
  svg {
    color: var(--brand-primary);
    margin-right: 5px;
  }
`;

const ErrorDescription = styled.div`
  line-height: 1.3;
  max-width: 400px;

  strong {
    font-weight: 700;
  }
`;

const Error = (props) => {
  const icon = props.content.icon ? props.content.icon : "exclamation-triangle";
  const header = props.content.header
    ? props.content.header
    : strings.ERROR.fallback.header;
  const description = props.content.description
    ? props.content.description
    : strings.ERROR.fallback.description;
  const { showFeedbackButton } = props;
  return (
    <ErrorContainer fullscreen={props.fullscreen}>
      <ErrorHeader>
        <FontAwesomeIcon icon={["fas", icon]} /> {header}
      </ErrorHeader>
      <ErrorDescription>
        <Markdown>{description}</Markdown>
        {showFeedbackButton && (
          <Button
            onClick={() =>
              Sentry.showReportDialog({ eventId: showFeedbackButton })
            }
          >
            {strings.ERROR_REPORT.buttonText}
          </Button>
        )}
      </ErrorDescription>
    </ErrorContainer>
  );
};

export default Error;
