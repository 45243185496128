// * NOTE: Any FontAwesome icons referenced anywhere in the project must be imported into the library here

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faFacebookF as fabFacebookF,
  faTwitter as fabTwitter,
} from "@fortawesome/free-brands-svg-icons";

import {
  faCalendar as falCalendar,
  faCalendarAlt as falCalendarAlt,
  faHomeAlt as falHomeAlt,
  faUsers as falUsers,
} from "@fortawesome/pro-light-svg-icons";

import {
  faPlus as farPlus,
  faCircle as farCircle,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faArchive as fasArchive,
  faBug as fasBug,
  faCalendar as fasCalendar,
  faCalendarAlt as fasCalendarAlt,
  faCheckCircle as fasCheckCircle,
  faClock as fasClock,
  faEnvelope as fasEnvelope,
  faGhost as fasGhost,
  faHomeAlt as fasHomeAlt,
  faImages as fasImages,
  faLightbulb as fasLightbulb,
  faPencil as fasPencil,
  faSkull as fasSkull,
  faStar as fasStar,
  faTimes as fasTimes,
  faUmbrellaBeach as fasUmbrellaBeach,
  faUser as fasUser,
  faUsers as fasUsers,
  faExclamationTriangle as fasExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";

const loadIcons = () => {
  // Load the icons into the global library
  library.add(
    fabFacebookF,
    fabTwitter,
    falCalendar,
    falCalendarAlt,
    falHomeAlt,
    falUsers,
    farCircle,
    farPlus,
    fasArchive,
    fasBug,
    fasCalendar,
    fasCalendarAlt,
    fasCheckCircle,
    fasClock,
    fasEnvelope,
    fasExclamationTriangle,
    fasGhost,
    fasHomeAlt,
    fasImages,
    fasLightbulb,
    fasPencil,
    fasSkull,
    fasStar,
    fasTimes,
    fasUmbrellaBeach,
    fasUser,
    fasUsers
  );
};

export default loadIcons;
