import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, navigate } from "@reach/router";
import styled, { css } from "styled-components";

import * as routes from "../constants/routes";
import * as strings from "../constants/strings";

import { joinEvent, leadEvent, leaveEvent } from "../actions";

import EventUserSelect from "./EventUserSelect";
import Avatar from "../components/Avatar";
import DateTime from "./EventDateTime";
import Markdown from "../components/Markdown";
import Button, { ButtonRow } from "../components/Button";
import JoinButton from "../components/JoinButton";
import { ScreenReaderText } from "../components/Accessibility";

export const EventContainer = styled.div`
  background: var(--box-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-contrast);
  color: var(--color-text);
  display: flex;
  flex-flow: column nowrap;
  margin: 0 0 20px 0;
  padding: 20px 15px 20px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;

  .swiper-container & {
    margin-bottom: 0;
  }
`;

const EventDateTime = styled(DateTime)`
  ${(props) =>
    props.compact &&
    css`
      margin-right: 10px;
    `};
`;

const EventDateTimeLabel = styled.h4`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 25px;
`;

const EventType = styled.div`
  color: var(--brand-primary);
  font-size: 12px;
  font-weight: 600;
  margin: 0 0 5px;
  text-transform: uppercase;
`;

const EventContentWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 10px;
`;

const EventContent = styled.div`
  flex: 1;
`;

const EventTitle = styled.h3`
  display: -webkit-box;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.05;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-box-orient: vertical;

  ${(props) =>
    props.view === "compact" &&
    css`
      max-height: 68px;
      -webkit-line-clamp: 3;
    `};
`;

const EventParticipants = styled.div`
  display: flex;
  flex-flow: row wrap;
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;
  width: 100%;

  > div {
    margin-right: 18px;
  }

  h4 {
    font-weight: 700;
    font-size: 14px;
    margin: 0 0 5px 0;
    display: block;
  }

  ul {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    list-style: none;
    margin: 0 20px 0 -2px;
    padding: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  li {
    margin: 0 -7px 0 0;
    z-index: 1;
  }

  ${(props) =>
    props.view === "details" &&
    css`
      flex-flow: column nowrap;
      margin-top: 30px;
      width: 100%;

      ul {
        margin-bottom: 20px;
        margin-right: 0;
      }

      li {
        margin: 0;
      }
    `};
`;

const EventDescription = styled.div`
  font-size: 16px;
  line-height: 1.2;
  margin: 15px 0 0;
  word-break: break-word;

  p,
  ul {
    margin: 0 0 1em 0;
  }
`;

const EventParticipantsMore = styled.li`
  color: var(--text-grey);
  font-size: 12px;
  margin-left: 10px !important;
`;

// const LeadButton = styled.button`
//   align-self: center;
//   background: var(--brand-primary);
//   border: 2px solid var(--brand-primary);
//   border-radius: var(--border-radius-button);
//   color: white;
//   font-size: 12px;
//   font-weight: 700;
//   cursor: pointer;
//   line-height: 1;
//   margin: 0;
//   padding: 3px 5px 2px;
//   position: relative;
//   text-transform: uppercase;
//   top: -2px;
//   white-space: nowrap;
//   transition: var(--transition-time) opacity;
//   z-index: 1;

//   &:hover {
//     opacity: 0.85;
//   }
// `;

const EventLink = styled(Link)`
  bottom: 0;
  /* display: none; */
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
`;

export class EventItem extends Component {
  renderJoinButton = () => {
    const { participants, leads, id, start_date } = this.props.event;
    const { user, view } = this.props;
    const joinEvent = () => this.props.joinEvent(id, user.id);
    const leaveEvent = () => this.props.leaveEvent(id, user.id);

    let membership = "isEmpty";

    if (!start_date) {
      membership = "isEmptyIdea";
    }

    if (participants.length > 0) {
      membership = "isNotParticipant";

      if (!start_date) {
        membership = "isNotParticipantIdea";
      }
    }

    if (leads.find((leadId) => leadId === user.id)) {
      membership = "isLead";
    }

    if (participants.find((participantId) => participantId === user.id)) {
      membership = "isParticipant";

      if (!start_date) {
        membership = "isParticipantIdea";
      }
    }

    return (
      <JoinButton
        membership={membership}
        joinEvent={joinEvent}
        leaveEvent={leaveEvent}
        disabled={!user.is_in_house}
        view={view}
      />
    );
  };

  renderLeads = (view) => {
    const { user, event } = this.props;
    const { leads } = event;
    const limit = 1;

    const leadsShortlist = leads.length > 0 ? leads.slice(0, limit) : null;

    if (view === "details") {
      return (
        <>
          <h4>
            {leads.length > 0
              ? strings.EVENT.membership.any_leads
              : strings.EVENT.membership.no_leads}
          </h4>
          <ul>
            <li>
              <EventUserSelect
                disabled={!user.is_in_house}
                event={event}
                mode="leads"
                visibleButton={leads.length === 0}
              >
                <Avatar
                  action="manageParticipants"
                  disabled={!user.is_in_house}
                  size="l"
                  userId={null}
                />
              </EventUserSelect>
            </li>
            {leads.map((userId) => (
              <li key={userId}>
                <Avatar userId={userId} size="l" showName />
              </li>
            ))}
            <li>
              <EventUserSelect
                disabled={!user.is_in_house}
                visibleButton={leads.length > 0}
                event={event}
                mode="leads"
              />
            </li>
          </ul>
        </>
      );
    } else if (view === "compact") {
      return (
        <>
          {leads.length > 0 && (
            <ul>
              {leadsShortlist.map((userId) => (
                <li key={userId}>
                  <Avatar userId={userId} size="m" />
                </li>
              ))}
              {leads.length > leadsShortlist.length && (
                <EventParticipantsMore>
                  +{leads.length - leadsShortlist.length}
                </EventParticipantsMore>
              )}
            </ul>
          )}
        </>
      );
    }
  };

  renderParticipants = (view) => {
    const { event, user } = this.props;
    const { participants } = event;
    const limit = 6;

    const participantsShortlist =
      participants.length > 0 ? participants.slice(0, limit) : null;

    if (view === "details") {
      return (
        <>
          <h4>
            {participants.length > 0
              ? strings.EVENT.membership.any_participants
              : strings.EVENT.membership.no_participants}
          </h4>
          <ul>
            <li>
              <EventUserSelect
                disabled={!user.is_in_house}
                event={event}
                mode="participants"
                visibleButton={participants.length === 0}
              >
                <Avatar
                  action="manageParticipants"
                  disabled={!user.is_in_house}
                  empty
                  size="m"
                  userId={null}
                />
              </EventUserSelect>
            </li>
            {participants.map((userId) => (
              <li key={userId}>
                <Avatar userId={userId} size="m" showName />
              </li>
            ))}
            <li>
              <EventUserSelect
                disabled={!user.is_in_house}
                event={event}
                mode="participants"
                visibleButton={participants.length > 0}
              />
            </li>
          </ul>
        </>
      );
    } else if (view === "compact") {
      return (
        <div>
          {participants.length > 0 && (
            <ul>
              {participantsShortlist.map((userId) => (
                <li key={userId}>
                  <Avatar userId={userId} size="s" />
                </li>
              ))}
              {participants.length > participantsShortlist.length && (
                <EventParticipantsMore>
                  +{participants.length - participantsShortlist.length}
                </EventParticipantsMore>
              )}
            </ul>
          )}
        </div>
      );
    }
  };

  gotoEdit = () => {
    navigate(`${routes.EVENT_DIR}/${this.props.event.id}/edit/`);
  };

  render() {
    const {
      name,
      start_date,
      start_time,
      end_time,
      description,
      leads,
    } = this.props.event;

    const { user } = this.props;
    const eventTypes = this.props.eventTypes;
    const kind = eventTypes[this.props.event.kind].name;
    const { view } = this.props;

    return (
      <EventContainer type={kind} className={this.props.className}>
        <EventContentWrapper>
          {view === "compact" && start_date && (
            <EventDateTime
              date={{ start_date, start_time, end_time }}
              compact
            />
          )}
          <EventContent>
            <EventType type={kind}>{kind}</EventType>
            <EventTitle view={view}>{name}</EventTitle>

            {view === "details" && description && (
              <EventDescription>
                <Markdown>{description}</Markdown>
              </EventDescription>
            )}

            {view === "details" && start_date && (
              <>
                <EventDateTimeLabel>
                  {strings.EVENT.date_statement}
                </EventDateTimeLabel>
                <EventDateTime date={{ start_date, start_time, end_time }} />
              </>
            )}
            <EventParticipants view={view}>
              {this.renderLeads(view)}
              {this.renderParticipants(view)}
            </EventParticipants>
          </EventContent>
        </EventContentWrapper>

        <ButtonRow>
          {view === "details" && (
            <Button
              disabled={!user.is_in_house}
              onClick={this.gotoEdit}
              secondary
            >
              {strings.EVENT.edit}
            </Button>
          )}
          {view === "compact" && leads.length === 0 && (
            <Button
              disabled={!user.is_in_house}
              onClick={() =>
                this.props.leadEvent(this.props.event.id, this.props.user.id)
              }
              secondary
              // style={{ flex: "0.25 0 auto" }}
            >
              {strings.EVENT.membership.me_lead}
            </Button>
          )}
          {this.renderJoinButton()}
        </ButtonRow>
        {view === "compact" && (
          <EventLink
            to={`${routes.EVENT_DIR}/${this.props.event.id}`}
            title={strings.EVENT.view}
          >
            <ScreenReaderText>{strings.EVENT.view}</ScreenReaderText>
          </EventLink>
        )}
      </EventContainer>
    );
  }
}

EventItem.propTypes = {
  event: PropTypes.object.isRequired,
  eventTypes: PropTypes.object.isRequired,
  joinEvent: PropTypes.func.isRequired,
  leadEvent: PropTypes.func.isRequired,
  leaveEvent: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
};

const mapStateToprops = (state) => {
  return {
    user: state.user,
    eventTypes: state.eventTypes,
  };
};

const mapDispatchToProps = {
  joinEvent,
  leadEvent,
  leaveEvent,
};

export default connect(mapStateToprops, mapDispatchToProps)(EventItem);
