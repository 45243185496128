import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactContentLoader from "react-content-loader";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

import * as strings from "../constants/strings";
import * as routes from "../constants/routes";
import { CACHE_DURATION } from "../constants";

import { setMeta, fetchEvent, refreshEvent } from "../actions";

import { ContentContainer, LeftNavContainer } from "../components/Layout";
import EventItem from "./EventItem";
import FloatingButton from "../components/FloatingButton";
import { BackLink } from "../components/Link";
import EventPlaceholder from "./EventPlaceholder";
import Error from "../views/Error";

class EventView extends Component {
  componentDidMount = () => {
    const { event } = this.props;

    this.props.fetchEvent(this.props.itemId);

    this.props.setMeta({
      navActive: "events",
      parent: routes.EVENT_LIST,
      title: strings.TITLES.event,
      titleBrowser: !isEmpty(event) ? event.name : strings.TITLES.event,
    });

    this.refreshEvent = setInterval(() => {
      this.props.refreshEvent(this.props.itemId);
    }, CACHE_DURATION);
  };

  componentDidUpdate = (prevProps) => {
    const { event } = this.props;

    if (!isEmpty(event) && !isEqual(event, prevProps.event)) {
      this.props.setMeta({
        titleBrowser: event.name,
      });
    }
  };

  // Make sure we're also listening for navigation between events
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.itemId !== nextProps.itemId) {
      this.props.fetchEvent(nextProps.itemId);
      this.props.setMeta({
        titleBrowser: this.props.event.name,
      });
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.refreshEvent);
  };

  renderContent = () => {
    const { event, isFetching } = this.props;

    if (isFetching) {
      return (
        <>
          <EventPlaceholder>
            <ReactContentLoader
              style={{ height: 270, width: "100%", marginBottom: "20px" }}
            />
          </EventPlaceholder>
        </>
      );
    }

    if (!event) {
      return <Error content={strings.ERROR_EVENT_404} />;
    }

    return (
      <>
        <EventItem event={event} view="details" />
      </>
    );
  };

  render() {
    const { event, isFetching, user } = this.props;

    return (
      <>
        {(event || isFetching) && (
          <LeftNavContainer>
            <BackLink to={routes.EVENT_LIST}>{strings.EVENT.back}</BackLink>
          </LeftNavContainer>
        )}
        <ContentContainer>{this.renderContent()}</ContentContainer>
        {user.is_in_house && <FloatingButton />}
      </>
    );
  }
}

EventView.propTypes = {
  event: PropTypes.object,
  fetchEvent: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  refreshEvent: PropTypes.func.isRequired,
  setMeta: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    event: state.events.items[ownProps.itemId],
    isFetching: state.events.isFetching,
    user: state.user,
  };
};

const mapDispatchToProps = {
  setMeta,
  fetchEvent,
  refreshEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventView);
