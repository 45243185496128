import React from "react";
import PropTypes from "prop-types";

import Placeholder from "../components/Placeholder";

const ResourcePlaceholder = (props) => {
  let placeholder = [];
  const count = props.count ? props.count : 1;
  for (let i = 0; i < count; i++) {
    placeholder.push(
      <Placeholder
        height={46}
        style={{
          display: "block",
        }}
        key={i}
      >
        <circle cx="16" cy="24" r="13" />
        <rect x="42" y="2" rx="2" ry="2" width="160" height="12" />
        <rect x="42" y="22" rx="2" ry="2" width="160" height="10" />
        <rect x="42" y="38" rx="2" ry="2" width="100" height="10" />
      </Placeholder>
    );
  }
  return <div>{placeholder}</div>;
};

ResourcePlaceholder.propTypes = {
  count: PropTypes.number,
};

export default ResourcePlaceholder;
