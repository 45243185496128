import React, { Component } from "react";
import PropTypes from "prop-types";
import { Router } from "@reach/router";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import isEmpty from "lodash/isEmpty";

import * as routes from "./constants/routes";
import * as strings from "./constants/strings";

import "./App.css";
import "./branding/custom.css";

import { confirmAuth } from "./actions";

// import views
import ConfirmRegistration from "./views/ConfirmRegistration";
import Loading from "./views/Loading";
import EventList from "./Events/EventList";
import EventView from "./Events/EventView";
import EventEdit from "./Events/EventEdit";
import EventCreate from "./Events/EventCreate";
import Dashboard from "./views/Dashboard";
import Error from "./views/Error";
import Login from "./views/Login";
import Page from "./Pages/Page";
import UserList from "./Users/UserList";
import Profile from "./Profile/Profile";
import Notice from "./components/Notice";

// import components
import Header from "./components/Header";
import { AppWrapper } from "./components/Layout";

class App extends Component {
  componentDidMount() {
    this.props.confirmAuth();
  }

  renderApp() {
    const { isAuthenticated, loading, errors } = this.props.auth;
    const { user } = this.props;

    if (!isEmpty(errors)) {
      return <Error content={strings.ERROR_SERVER_CONNECTION} fullscreen />;
    }

    if (loading) {
      return <Loading />;
    }

    if (isAuthenticated) {
      if (user.error) {
        return <Error content={strings.ERROR_SERVER_CONNECTION} fullscreen />;
      }

      if (user.is_active !== true) {
        return (
          <ConfirmRegistration
            path={routes.PENDING}
            userFirstName={user.first_name}
          />
        );
      }

      return (
        <>
          <Header />
          <AppWrapper>
            {!user.is_in_house && (
              <Notice>{strings.NOTICE.user_not_in_house}</Notice>
            )}
            <Router primary={false}>
              <Dashboard path={routes.DASHBOARD} />
              <EventList path={routes.EVENT_LIST} />
              <EventList path={routes.EVENTS_FILTERED} />
              <EventCreate path={routes.EVENT_CREATE} />
              <EventView path={routes.EVENT_VIEW} />
              <EventEdit path={routes.EVENT_EDIT} />
              <UserList path={routes.USERS} />
              <UserList path={routes.USER_ID} />
              <Profile path={routes.PROFILE} />
              <Page path={routes.PAGE_VIEW} />
              <Error default content={strings.ERROR_404} />
            </Router>
          </AppWrapper>
        </>
      );
    }

    return <Login />;
  }

  render() {
    return (
      <div className="App">
        <ToastContainer
          autoClose={false}
          position={toast.POSITION.TOP_CENTER}
        />
        {this.renderApp()}
      </div>
    );
  }
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  confirmAuth: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
    users: state.users,
  };
};

const mapDispatchToProps = {
  confirmAuth,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
