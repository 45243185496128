import mapKeys from "lodash/mapKeys";

import * as ACTION from "../actions/types";

const usersReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTION.USERS_FETCH_SUCCESS:
      return { ...state, ...mapKeys(action.payload, "id") };
    case ACTION.USER_FETCH_REQUEST:
      return {
        ...state,
        [action.payload]: {
          isFetching: true,
        },
      };
    case ACTION.USER_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
};

export default usersReducer;
