import * as routes from "./routes";
import * as brandData from "../branding/brandData";
import { capitalizeFirstLetter } from "../helpers";

/*
 * NOTE: Any FontAwesome icons referenced below must be imported into the library in `/frontend/src/constants/icons.js`
 */

export const LOADING = {
  fetching_data: "Fetching data…",
};

export const ERROR = {
  fallback: {
    header: "Error",
    description: "There was an error.",
  },
};

export const ERROR_REPORT = {
  buttonText: "Provide feedback",
  description:
    "There was a fatal error. Our team has been notified. If you want to help us out, please let us know how it happened.",
  header: "We're having some issues.",
  icon: "bug",
};

export const ERROR_SERVER_CONNECTION = {
  header: "Oops.",
  description: `**There was an error connecting to the server.**\n\nWe're sorry for the inconvenience. Please try again later and [get in touch](${process.env.REACT_APP_SUPPORT_URL}) if this error persists.\n\n${process.env.REACT_APP_COMPANY_SIGNATURE}`,
};

export const ERROR_404 = {
  header: "There's nothing here.",
  icon: "skull",
  description: `**This page does not exist.**\n\nWere you expecting something else? If you have a minute, please [let us know](${process.env.REACT_APP_SUPPORT_URL}) how this happened so we can fix it.\n\n${process.env.REACT_APP_COMPANY_SIGNATURE}\n\n[← Back to the dashboard](${routes.DASHBOARD})`,
};

export const ERROR_EVENT_404 = {
  header: "Spooky!",
  icon: "ghost",
  description: `This event doesn't exist. Perhaps you saw a ghost?\n\n[← Back to the event board](${routes.EVENT_LIST})`,
};

export const ERROR_PAGE_404 = {
  header: "Spooky!",
  icon: "ghost",
  description: `This page doesn't exist. Perhaps you saw a ghost?\n\n[← Back to the dashboard](${routes.DASHBOARD})`,
};

export const API_ERRORS = {
  SERVER_NORESPONSE: "Server is not responding. Please try again later.",
  SERVER_NODATA:
    "There was an error fetching data from the server. Please try again later.",
  SERVER_FAILED_ACTION:
    "There was an error performing this action. Please try again later.",
};

export const FALLBACK_EVENTS_NONE = {
  header: "No events yet!",
  icon: "ghost",
  text: "This is your event board. Add your first event to get started.",
  buttonText: "Add your first event",
};

export const FALLBACK_EVENTS_UPCOMING = {
  header: "No upcoming events",
  icon: "calendar",
  text: `Looks like it's time to create some new events! Also check out the [ideas](/events/ideas), you might be able to turn some of them into events.`,
  buttonText: "Create a new event",
};

export const FALLBACK_EVENTS_MINE = {
  header: "You haven't joined any events",
  icon: "star",
  text:
    "You haven't created or joined any events yet. Check out some of the upcoming events or create your own!",
  buttonText: "View upcoming events",
};

export const FALLBACK_EVENTS_NEXT = {
  header: "Your next event?",
  icon: "clock",
  text:
    "Join one of the upcoming events or create your own! It'll show up here as a reminder.",
  buttonText: "View upcoming events",
};

export const FALLBACK_EVENTS_IDEAS = {
  header: "No ideas yet!",
  icon: "lightbulb",
  text: `**Ideas** are events that don't have dates yet. This is a great way to bounce around ideas with your fellow ${brandData.SETTINGS.users} before turning them into proper events. Add your first idea to get started.`,
  buttonText: "Add your first idea",
};

export const FALLBACK_EVENTS_PAST = {
  header: "No past events yet!",
  icon: "archive",
  text:
    "This is where past events will show up. Create a new event to get started.",
  buttonText: "Add your first event",
};

export const FALLBACK_EVENT_TYPE = {
  header: "No %ss yet!",
  icon: "ghost",
  text: "This is your event board. Add your first %s.",
  buttonText: "Create a %s",
};

export const TITLES = {
  dashboard: "Dashboard",
  event: "Event",
  event_new: "Create a new event",
  event_edit: "Edit this event",
  event_list: "Event Board",
  profile: "Profile",
  profile_mine: "My Profile",
  users_list: brandData.SETTINGS.users,
};

export const LOGIN = {
  button_login: "Log in with Google",
  explainer_open: "Why Google?",
  explainer_ok: "Ok",
  explainer_close: "Close",
};

export const LOGOUT = {
  SUCCESS: "You logged out. See you again soon!",
  TOKEN_EXPIRED: "Your session has expired. You'll need to log in again.",
};

export const NAVIGATION = [
  {
    active: "dashboard",
    title: "Dashboard",
    icon: "home-alt",
    link: routes.DASHBOARD,
  },
  {
    active: "events",
    title: "Events",
    icon: "calendar-alt",
    link: routes.EVENT_LIST,
  },
  {
    active: "users",
    title: capitalizeFirstLetter(brandData.SETTINGS.users),
    icon: "users",
    link: routes.USERS,
  },
];

export const FILTER = {
  upcoming: "Upcoming",
  ideas: "Ideas",
  mine: "Mine",
  past: "Past",
};

export const DASHBOARD = {
  upcoming: "Upcoming Events",
  upcoming_cta: "View more upcoming events",
  ideas: "Ideas",
  ideas_cta: "View more ideas",
  mine_next: "Your next event%s",
  mine_next_cta: "View more of your events",
  in_house: "Who's in the House?",
  in_house_cta: `Meet your fellow ${brandData.SETTINGS.users}`,
};

export const EVENT = {
  edit: "Edit",
  create: "Create event",
  update: "Update event",
  view: "View event",
  type: "Type of event",
  type_placeholder: "Please select an event type",
  title: "Title of the event",
  title_placeholder: "Enter title here…",
  description: "Description",
  description_placeholder: "Enter a description here…",
  date: "When is this happening?",
  date_placeholder: "Select date",
  time_start: "Starts",
  time_end: "Ends",
  time_placeholder: "Select time",
  required: "required",
  cancel: "Cancel",
  delete: "Delete",
  delete_confirm:
    "Are you sure you want to delete this event? This cannot be undone!",
  current: "happening now",
  current_compact: "now",
  date_statement: "When this is happening",
  choose: {
    participants: "Choose participants",
    leads: "Choose leads",
  },
  confirm_move_lead:
    "is already leading this event. Move them to participants?",
  lead_event: "Lead this event",
  join_event: "Join this event",
  back: "Back to the board",
  membership: {
    me_join: "Attend",
    me_joined: "I'm attending",
    me_lead: "Lead",
    me_leading: "Led by me",
    me_join_idea: "I'm interested",
    me_joined_idea: "I'm interested ✓",
    me_remove: "Leave event",
    me_remove_idea: "Remove me",
    any_leads: "Led by",
    no_leads: "Choose someone to lead this:",
    any_participants: "Attending",
    no_participants: "Add some participants:",
  },
  locked_by: "This event is currently being edited by ",
  locked_try_again: ". Please try again in a few minutes.",
};

export const IDEA = {
  explainer:
    "**Ideas** are simply events without dates. They enable you to quickly jot down possible activities without having to decide on a day. Just add a date and time when you're ready.",
};

export const PROFILE = {
  full_name: "My full name",
  first_name: "First name",
  last_name: "Last name",
  update: "Update profile",
  questions_header: "Let's get to know each other ☀️",
  questions_notice: `Note: your answers will be visible on the [${brandData.SETTINGS.users}](${routes.USERS}) page.`,
  google_account: "This account is linked to your Google account",
  joined: "Joined in",
  logout: "Log out",
  avatar_change: "Change avatar",
  avatar_upload_header: "Upload a New Avatar",
  avatar_overlay_arialabel: "Upload a New Avatar",
  avatar_upload_button: "Upload avatar",
  avatar_instructions:
    "Drag & drop a profile picture here, or tap to select a file!",
  avatar_rejected: "File must be a JPEG, PNG or GIF",
  avatar_ok: "Ok",
  avatar_cancel: "Cancel",
};

export const INTEGRATIONS = {
  title: "Integrations",
};

export const COPYSHARE = {
  success: "Copied to clipboard",
  button: "Copy",
};

export const CALENDAR = {
  title: `Add your events to your calendar`,
  connect: "Connect",
  explainer:
    "Never miss an event! Subscribe to our calendars by copying these URLs and adding them to your calendar app. They'll stay in sync so you're always up-to-date.",
  header_all: "Add ALL events",
  header_mine: "Add only MY events",
  overlay_header: "Integrate with your calendar",
  overlay_arialabel: "Calendar feeds",
};

export const RESOURCES = {
  title: "Everyday Helpers",
};

export const PAGES = {
  title: "Information",
};

export const LIGHTBOX = {
  close: "Close",
};

export const TIME = {
  today: "today",
  tomorrow: "tomorrow",
  starts: "starts in",
  is: "is",
  is_in: "is in",
  is_on: "is on",
  happening_now: "is happening now",
};

export const USER = {
  answerMissing: "has not answered this question yet.",
};

export const USERS = {
  close: "Close",
  header_users: `Meet your fellow ${brandData.SETTINGS.users}`,
  header_hosts: "Hosts",
};

export const NOTICE = {
  user_not_in_house:
    "It appears that you're not staying in the house at the moment, so some features are disabled. If you believe this is an error, please contact a host.",
};
