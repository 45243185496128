import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, navigate } from "@reach/router";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useMedia from "react-use/lib/useMedia";
import "swiper/swiper-bundle.min.css";
import isEmpty from "lodash/isEmpty";

import * as routes from "../constants/routes";
import * as strings from "../constants/strings";

import { getRelativeTime } from "../helpers";

import EventPlaceholder from "./EventPlaceholder";
import Fallback from "../components/Fallback";
import EventItem, { EventContainer } from "./EventItem";
import { filterEvents } from "./EventFilter";
import {
  WidgetTitle,
  WidgetTitleLink,
  WidgetTitleIcon,
  WidgetHeader,
} from "../components/DashboardWidgetTitle";

import moreIcon from "../icons/more.svg";

SwiperCore.use([Navigation, Pagination, A11y]);

const WidgetList = styled.div`
  margin: 0 -10px 30px;
  padding: 0 10px 30px;

  @media (max-width: 768px) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    .swiper-slide {
      max-width: 320px !important;
      opacity: 1;
      width: 80vw !important;
    }
  }
`;

const WidgetFallback = styled(Fallback)`
  margin: 0 0 40px;
`;

const WidgetCTA = styled(Link)`
  align-items: center;
  background: var(--color-cta);
  border-radius: var(--border-radius);
  color: white;
  display: flex;
  justify-content: center;
  text-decoration: none;
  width: 100%;
`;

const WidgetSingle = styled.div`
  border-bottom: 1px solid var(--color-border--light);
  margin: 0 0 30px;
`;

const sliderParams = {
  freeMode: true,
  freeModeMomentumVelocityRatio: 1.5,
  navigation: true,
  pagination: true,
  slidesPerView: "auto",
  simulateTouch: true,
  spaceBetween: 10,
  slideToClickedSlide: false,
};

const DashboardWidgetEvents = ({
  ctaLink,
  ctaText,
  events,
  filter,
  isFetching,
  limit,
  title,
  user,
}) => {
  const [nextEvent, setNextEvent] = useState(null);
  const sliderBreakpoint = useMedia(`(max-width: 1000px)`);

  useEffect(() => {
    if (
      !isFetching &&
      !isEmpty(filterEvents(filter, events, user)) &&
      filter === "next"
    ) {
      setNextEvent(filterEvents(filter, events, user, 1)[0]);
    }
  }, [events, filter, isFetching, user]);

  const renderList = () => {
    if (isFetching) {
      return sliderBreakpoint ? (
        filter === "next" ? (
          <WidgetSingle>
            <EventPlaceholder count={1} container={true} />
          </WidgetSingle>
        ) : (
          <WidgetList>
            <Swiper {...sliderParams}>
              {Array(4)
                .fill("")
                .map((item, i) => (
                  <SwiperSlide key={i}>
                    <EventContainer className="isWidget">
                      <EventPlaceholder
                        style={{
                          boxShadow: "none",
                          height: "150px",
                          padding: 0,
                        }}
                      />
                    </EventContainer>
                  </SwiperSlide>
                ))}
            </Swiper>
          </WidgetList>
        )
      ) : filter === "next" ? (
        <WidgetSingle>
          <EventPlaceholder count={1} container={true} />
        </WidgetSingle>
      ) : (
        <EventPlaceholder count={3} container={true} />
      );
    }

    if (isEmpty(filterEvents(filter, events, user))) {
      let content, func, disabled;

      switch (filter) {
        case "next":
          content = strings.FALLBACK_EVENTS_NEXT;
          func = () => navigate(routes.EVENT_LIST);
          break;
        case "mine":
          content = strings.FALLBACK_EVENTS_MINE;
          func = () => navigate(routes.EVENT_LIST);
          break;
        case "ideas":
          content = strings.FALLBACK_EVENTS_IDEAS;
          disabled = !user.is_in_house;
          func = () => navigate(routes.EVENT_CREATE);
          break;
        case "past":
          content = strings.FALLBACK_EVENTS_PAST;
          disabled = !user.is_in_house;
          func = () => navigate(routes.EVENT_CREATE);
          break;
        default:
          content = strings.FALLBACK_EVENTS_UPCOMING;
          disabled = !user.is_in_house;
          func = () => navigate(routes.EVENT_CREATE);
          break;
      }

      return (
        <WidgetFallback
          content={content}
          buttonFunc={func}
          disabled={disabled}
        />
      );
    }

    if (filter === "next") {
      return (
        <WidgetSingle>
          {filterEvents(filter, events, user, limit).map((item) => (
            <EventItem
              event={item}
              key={item.id}
              isWidget={true}
              className="isWidget"
              view="compact"
            />
          ))}
        </WidgetSingle>
      );
    }

    return (
      <>
        <WidgetList>
          {sliderBreakpoint ? (
            <Swiper {...sliderParams}>
              {filterEvents(filter, events, user, limit).map((item) => (
                <SwiperSlide key={item.id}>
                  <EventItem
                    event={item}
                    isWidget={true}
                    className="isWidget"
                    view="compact"
                  />
                </SwiperSlide>
              ))}
              {ctaText && filter !== "next" && (
                <SwiperSlide key="more">
                  <WidgetCTA to={ctaLink}>{ctaText}</WidgetCTA>
                </SwiperSlide>
              )}
            </Swiper>
          ) : (
            <>
              {filterEvents(filter, events, user, limit).map((item) => (
                <EventItem
                  event={item}
                  key={item.id}
                  isWidget={true}
                  className="isWidget"
                  view="compact"
                />
              ))}
              {/* {ctaText && filter !== "next" && (
                    <Button
                      onClick={() => navigate(ctaLink)}
                      style={{ width: "100%" }}
                    >
                      {ctaText}
                    </Button>
                  )} */}
            </>
          )}
        </WidgetList>
      </>
    );
  };

  const renderTitle = () => {
    let localTitle = title;

    if (localTitle && filter === "next" && nextEvent) {
      const day = nextEvent.start_date;
      const time = nextEvent.start_time;
      const relativeTime = getRelativeTime(day, time);

      localTitle = localTitle.replace("%s", relativeTime);
    } else {
      localTitle = localTitle.replace("%s", "");
    }

    if (title && ctaText && ctaLink) {
      return (
        <WidgetTitle>
          <WidgetTitleLink to={ctaLink} title={ctaText}>
            {localTitle}{" "}
            <WidgetTitleIcon>
              <img src={moreIcon} alt={ctaText} />
            </WidgetTitleIcon>
          </WidgetTitleLink>
        </WidgetTitle>
      );
    } else if (title) {
      return <WidgetTitle>{title}</WidgetTitle>;
    }
  };

  return (
    <div>
      <WidgetHeader>{renderTitle()}</WidgetHeader>
      {renderList()}
    </div>
  );
};

DashboardWidgetEvents.propTypes = {
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  events: PropTypes.array.isRequired,
  filter: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
};

export default DashboardWidgetEvents;
