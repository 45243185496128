import styled, { css } from "styled-components";

const FormActions = styled.div`
  display: flex;

  > span button {
    flex: 1 0 auto;

    @media (min-width: 768px) {
      flex: none;
    }
  }

  ${(props) =>
    props.sticky &&
    css`
      @supports (position: sticky) {
        border-top: 1px solid var(--color-border--medium);
        bottom: calc(var(--navbar-height) + 10px + env(safe-area-inset-bottom));
        margin: 10px -15px -20px;
        position: sticky;
        will-change: transform;
        transform: translate3d(0, 0, 0);

        > span {
          display: flex;
          background: var(--box-bg--transparent);
          border-bottom-left-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
          box-shadow: var(--box-shadow-contrast);
          padding: 15px;
          position: relative;
          z-index: 1;
          width: 100%;
        }

        &:after {
          background: var(--body-bg);
          position: absolute;
          bottom: -10px;
          z-index: 0;
          left: -10px;
          right: -10px;
          content: "";
          height: 15px;
        }

        @media (min-width: 1001px) {
          bottom: calc(10px + env(safe-area-inset-bottom));

          &:after {
            bottom: calc(-10px - env(safe-area-inset-bottom));
            height: calc(15px + env(safe-area-inset-bottom));
          }
        }
      }

      @supports (backdrop-filter: blur(10px)) {
        > span {
          backdrop-filter: blur(10px);
          background: var(--box-bg--transparent-blur);
        }
      }
    `};
`;

export default FormActions;
