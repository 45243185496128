import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import sortBy from "lodash/sortBy";

import * as routes from "../constants/routes";
import * as strings from "../constants/strings";

import { setMeta, fetchUsers } from "../actions";
import { normalizeCharsForSort, capitalizeFirstLetter } from "../helpers";

import { ContentContainer } from "../components/Layout";
import UserListItem from "./UserListItem";

const UserListContainer = styled.ul`
  list-style: none;
  margin: 0 0 40px;
  padding: 0;
  will-change: height;
  transform: translate3d(0, 0, 0);
`;

export class UserList extends Component {
  componentDidMount() {
    this.props.setMeta({
      navActive: "users",
      parent: routes.USERS,
      title: capitalizeFirstLetter(strings.TITLES.users_list),
    });
  }

  renderUserList = (users) => {
    return (
      <UserListContainer>
        {users.map((user, i) => (
          <UserListItem
            user={user}
            key={user.id}
            id={user.id}
            active={this.props.userId === user.id}
          />
        ))}
      </UserListContainer>
    );
  };

  render() {
    const { users } = this.props;
    const members = users.filter((user) => user.is_in_house && !user.is_host);
    const hosts = users.filter((user) => user.is_in_house && user.is_host);

    return (
      <ContentContainer>
        <h2>{strings.USERS.header_users}</h2>
        {this.renderUserList(members)}
        <h2>{strings.USERS.header_hosts}</h2>
        {this.renderUserList(hosts)}
      </ContentContainer>
    );
  }
}

UserList.propTypes = {
  users: PropTypes.array.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  setMeta: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    users: sortBy(
      Object.values(state.users),
      [
        (user) =>
          user && user.first_name && normalizeCharsForSort(user.first_name),
        (user) =>
          user && user.last_name && normalizeCharsForSort(user.last_name),
      ],
      [("first_name", "last_name")]
    ),
    user: state.user,
  };
};

const mapDispatchToProps = {
  setMeta,
  fetchUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
