import { toast } from "react-toastify";

import * as ACTION from "./types";
import { staticPages } from "../branding/brandData";
import { captureException } from "../helpers";
// import django from "../apis/django";

export const fetchPages = () => async (dispatch, getState) => {
  // prevent a refetch once pages have been loaded
  if (getState().pages.items.length > 0) {
    return null;
  }

  dispatch({ type: ACTION.PAGES_FETCH_REQUEST });

  try {
    // const response = await django.get("/pages/");
    const response = { data: [...staticPages] };

    dispatch({
      type: ACTION.PAGES_FETCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ACTION.PAGES_FETCH_ERROR,
      payload: error.message,
    });
    toast.error(error.message);
    captureException(error);
  }
};
