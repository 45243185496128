import DatePicker from "react-datepicker";
import styled from "styled-components";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

const InputDatePicker = styled(DatePicker)`
  background: var(--box-bg);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-input-border);
  color: var(--color-text);
  display: block;
  outline-offset: -2px;
  padding: 10px;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    border-color: var(--brand-primary);
    outline: 1px solid var(--brand-primary);
  }

  &::placeholder {
    opacity: 1;
  }
`;

export default InputDatePicker;
