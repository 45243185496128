import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const UploadBarContainer = styled.div`
  background: var(--color-border--light);
  border-radius: var(--border-radius-sm);
  height: 5px;
  opacity: 0;

  ${(props) =>
    (props.active || props.complete) &&
    css`
      opacity: 1;
    `};
`;

const UploadBarStatus = styled.div`
  animation: animate-stripes 2s linear infinite;
  background: var(--color-cta);
  border-radius: var(--border-radius-sm);
  height: 100%;
  transition: width var(--transition-time);
  width: 0;
  transform: translate3d(0, 0, 0);
  background-size: 15px 15px;
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.25) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.25) 50%,
    rgba(255, 255, 255, 0.25) 75%,
    transparent 75%,
    transparent
  );

  ${(props) =>
    props.complete &&
    css`
      animation: none;
      background-color: var(--color-ok);
    `};

  @keyframes animate-stripes {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 60px 0;
    }
  }
`;

const UploadBar = (props) => {
  return (
    <UploadBarContainer {...props} active={props.active}>
      <UploadBarStatus
        complete={props.complete}
        style={{ width: `${props.percent}%` }}
      />
    </UploadBarContainer>
  );
};

UploadBar.propTypes = {
  percent: PropTypes.number,
};

export default UploadBar;
