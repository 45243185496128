import styled from "styled-components";
import { Link } from "@reach/router";

import { H2 } from "../components/Headings";

export const WidgetTitle = styled(H2)`
  align-items: center;
  display: flex;
  margin: 0 0 10px;
`;

export const WidgetTitleLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  text-decoration: none;

  &:hover > span {
    transform: translateX(3px);
  }
`;

export const WidgetTitleIcon = styled.span`
  display: block;
  height: 20px;
  margin-left: 6px;
  transition: transform var(--transition-time);
  width: 20px;

  img {
    max-width: 100%;
  }
`;

export const WidgetHeader = styled.div`
  display: flex;
`;
