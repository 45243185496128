// import React from "react";
import styled, { css } from "styled-components";

const Splitter = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 100%;
  font-size: 15px;
  margin: 30px 0px;

  &::before,
  &::after {
    content: "";
    background: var(--color-border--neutral);
    flex-grow: 1;
    font-size: 0px;
    height: 1px;
    line-height: 0px;
  }

  ${(props) =>
    props.kind === "silent" &&
    css`
      &::before,
      &::after {
        background: transparent;
      }
    `}

  ${(props) =>
    props.children &&
    css`
      &::before {
        margin-right: 8px;
      }

      &::after {
        margin-left: 8px;
      }
    `};
`;

export default Splitter;
