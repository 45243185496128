import * as ACTION from "../actions/types";

const initialState = {
  isFetching: false,
  error: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.ME_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ACTION.ME_FETCH_SUCCESS:
    case ACTION.ME_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    case ACTION.AVATAR_FETCH_SUCCESS:
      return {
        ...state,
        profile_picture: action.payload.profile_picture,
        profile_picture_small: action.payload.profile_picture_small,
        isFetching: false,
      };
    case ACTION.AVATAR_UPLOAD_START:
      return {
        ...state,
        avatar_upload: undefined,
        avatar_upload_complete: false,
      };
    case ACTION.AVATAR_UPLOAD_PROGRESS:
      return {
        ...state,
        avatar_upload: action.payload,
      };
    case ACTION.AVATAR_UPLOAD_SUCCESS:
      return {
        ...state,
        avatar_upload: undefined,
        avatar_upload_complete: true,
        profile_picture: action.payload.profile_picture,
        profile_picture_small: action.payload.profile_picture_small,
      };
    case ACTION.AVATAR_UPLOAD_RESET:
      return {
        ...state,
        avatar_upload: undefined,
        avatar_upload_complete: undefined,
      };
    case ACTION.ME_FETCH_ERROR:
    case ACTION.ME_UPDATE_ERROR:
    case ACTION.AVATAR_FETCH_ERROR:
    case ACTION.AVATAR_UPLOAD_ERROR:
      return { error: action.payload };
    default:
      return state;
  }
};

export default userReducer;
