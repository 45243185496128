import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import * as strings from "../constants/strings";

import Error from "../views/Error";
import { isLocalhost, captureException } from "../helpers";

class ExampleBoundary extends Component {
  state = { eventId: null };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (!isLocalhost()) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <Error
          content={strings.ERROR_REPORT}
          fullscreen
          showFeedbackButton={this.state.eventId}
        />
      );
    }

    //when there's no error, render children untouched
    return this.props.children;
  }
}

export default ExampleBoundary;
